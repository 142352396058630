import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./styles.css";

const PageContentLoader = ({ backgroundOpacity = 1 }) => {
  return (
    <div
      className="page-content-loader"
      style={{ backgroundColor: `rgba(255, 255, 255, ${backgroundOpacity})`, zIndex: 200 }}
    >
      <div className="page-content-loader-box">
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    </div>
  );
};

export default PageContentLoader;