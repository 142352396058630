import { useEffect, useState, useCallback } from "react";
import { useForm } from "antd/es/form/Form";
import useSearch from "../../user-management/useSearch";
import { useTranslation } from "react-i18next";
import useNotification from "../../../../components/notification/notification";
import { useNavigate, useLocation } from "react-router-dom";

export const useAdminResource = (
  ApiResource,
  listName,
  notificationPath,
  urlName = null
) => {
  const [form] = useForm();
  const { search, handleUpdateSearchValue } = useSearch();
  const { t } = useTranslation();
  const [selectedResource, setSelectedResource] = useState(undefined);
  const [resourceList, setResourceList] = useState([]);
  const [tags, setTags] = useState([]);
  const [categories, setCategories] = useState([]);
  const [hasImage, setHasImage] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [isUpdatedImage, setIsUpdatedImage] = useState(false);
  const { openNotification } = useNotification();
  const [factCitations, setFactCitations] = useState([]);
  const [factUrls, setFactUrls] = useState([]);
  const [filters, setFilters] = useState({});
  const [isLoading, setLoading] = useState();

  const navigate = useNavigate();
  const location = useLocation();

  const [sorting, setSorting] = useState({
    sortField: "updatedAt",
    sortOrder: "DESC"
  });

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const getResources = useCallback(async () => {
    setLoading(true);
    setResourceList([]);
    const api =
      search || filters || sorting
        ? ApiResource.get({ query: search, ...filters, ...sorting })
        : ApiResource.get();
    const response = await api;
    setResourceList(response.data[listName]);
    setAuthors(response.data.authors);
    setFactCitations(response.data.factCitations);
    setFactUrls(response.data.factUrls);
    setLoading(false);
  }, [ApiResource, search, filters, sorting]);

  const createResource = async (params) => {
    setLoading(true);
    await ApiResource.create(params).finally(() => {
      setSelectedResource(null);
      form.resetFields();
      setLoading(false);
    });
    getResources();
    openNotification({
      type: "success",
      message: (
        <p>
          {t(`${notificationPath}.created`, {
            title:
              listName === "facts" ? params.text : params.title || params.text,
          })}
        </p>
      ),
    });
  };

  const open = (resource, mode = 'show') => {
    setSelectedResource(resource);
    console.log("resource", resource);
    form.setFieldsValue({ ...resource, image: null, hasImage: false });
    if (mode === 'edit') {
      handleNavigate(`/administration/${urlName || listName}/${resource.id}/edit`);
    } else {
      handleNavigate(`/administration/${urlName || listName}/${resource.id}`);
    }
  };

  const close = async () => {
    await form.resetFields();
    setSelectedResource(null);
    // getResources();
    updateIsUpdatedImage(false);
    handleNavigate(`/administration/${urlName || listName}`);
  };

  const updateResource = async (id, params) => {
    setLoading(true);
    await ApiResource.update(id, params).finally(() => {
      setLoading(false);
    });

    await Promise.all([getResources(), getTags()]);
    openNotification({
      type: "success",
      message: (
        <p>
          {t(`${notificationPath}.submit_edit`, {
            title:
              listName === "facts" ? params.text : params.title || params.text,
          })}
        </p>
      ),
    });
  };

  const create = async () => {
    await form.resetFields();
    await form.setFieldValue("image", null);
    setSelectedResource({
      title: "Title",
      text: null,
      image: null,
      status: "draft",
    });
    handleNavigate(`/administration/${urlName || listName}/create`);
  };

  const isNewResourceValid = (resource) => {
    let isValid = true;

    if (listName === "pages") {
      if ( !resource.title || resource.title.trim() === "" ) {
        openNotification({
          type: "error",
          message: <p>{t(`${notificationPath}.no_title`)}</p>,
        });
        isValid = false;
      }

      if (!hasImage) {
        openNotification({
          type: "error",
          message: <p>{t(`${notificationPath}.no_image`)}</p>,
        });
        isValid = false;
      }

      if (resource.external && !(resource.link || '').startsWith("http")) {
        openNotification({
          type: "error",
          message: <p>{t(`${notificationPath}.invalid_link`)}</p>,
        });
        isValid = false;
      }
    }


    if (
      listName === "facts" &&
      (!resource.text || resource.text.trim() === "")
    ) {
      openNotification({
        type: "error",
        message: <p>{t(`${notificationPath}.no_title`)}</p>,
      });
      isValid = false;
    }

    return isValid;
  };

  const update = () => {
    const fields = form.getFieldsValue();
    const updatedSelectedResource = { ...selectedResource, ...fields };

    if (!isUpdatedImage) {
      delete updatedSelectedResource.image;
    }

    if (!isNewResourceValid(updatedSelectedResource)) return;

    setSelectedResource(updatedSelectedResource);

    return updatedSelectedResource.id
      ? updateResource(updatedSelectedResource.id, updatedSelectedResource)
      : createResource(updatedSelectedResource);
  };

  const remove = async (id, title) => {
    await ApiResource.remove(id);
    getResources();
    openNotification({
      type: "success",
      message: <p>{t(`${notificationPath}.deleted`, { title })}</p>,
    });
  };

  const getTags = useCallback(async () => {
    const response = await ApiResource.getTags();
    if (response.data && response.data.tags) {
      setTags(response.data.tags);
    }
  }, [ApiResource]);

  const getCategories = useCallback(async () => {
    const response = await ApiResource.getCategories();
    if (response.data && response.data.categories) {
      setCategories(response.data.categories);
    }
  }, [ApiResource]);

  useEffect(() => {
    getResources();
  }, [getResources]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const isFilterEmpty = (filter) => {
    return Object.keys(filter).length === 0 || 
      Object.values(filter).every(value => 
        (Array.isArray(value) && value.length === 0) || 
        (typeof value === "object" && value !== null && Object.keys(value).length === 0) || 
        value === undefined || value === null
      );
  };

  const updateSorting = useCallback((sortField, sortOrder) => {
    setSorting({ sortField, sortOrder });
  }, []);

  const updateFilterValue = useCallback((newFilters) => {
    let equal_filters = null
    setFilters((prevFilters) => {
      let empty_filters = isFilterEmpty(prevFilters) && isFilterEmpty(newFilters)
      equal_filters = JSON.stringify(prevFilters) !== JSON.stringify(newFilters) && empty_filters
      if (JSON.stringify(prevFilters) === JSON.stringify(newFilters)) {
        return prevFilters;
      }
      return newFilters;
    });
    if (!equal_filters) {
      setFilters(newFilters);
    } else {
    }
  }, []);

  const updateHasImage = (value) => setHasImage(value);

  const updateIsUpdatedImage = (value) => setIsUpdatedImage(value);

  return {
    form,
    list: resourceList,
    active: selectedResource,
    update,
    open,
    close,
    create,
    search,
    handleUpdateSearchValue,
    remove,
    tags,
    filters,
    isUpdatedImage,
    updateFilterValue,
    updateHasImage,
    updateIsUpdatedImage,
    authors,
    categories,
    factCitations,
    factUrls,
    isLoading,
    updateSorting
  };
};
