import ModalWrapper from "../../../../components/modal/modal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import "../styles.css";
import { Link } from "react-router-dom";

const UploadModal = ({ image, isOpen, close }) => {
  return (
    <ModalWrapper
      classNames="upload-modal"
      wrapClassName="upload-modal"
      isOpen={isOpen}
      onCancel={close}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      styles={{ padding: 0 }}
      footer={null}
      width={1105}
    >
      <div className="upload-modal-container">
        <div className="upload-modal-image-container">
          <LazyLoadImage
            src={image.signedUrl}
            alt={image.title}
            effect="blur"
            className="upload-modal-image"
            wrapperClassName="upload-modal-image-wrapper"
          />
        </div>
        <div className="upload-modal-meta">
          <div className="upload-meta-content">
            { image?.subject?.displayId && (
                <div className="meta-item">
                  <p className="meta-title">Subject ID</p>
                  <p className="meta-value">
                    <Link
                      to={`/subject-management/subject/${image.subjectId}#profile`}
                      className="subject-link"
                    >
                      {image.subject.displayId || "N/A"}
                    </Link>
                  </p>
                </div>
              )
            }
            <div className="meta-item">
              <p className="meta-title">Submission date</p>
              <p className="meta-value">
                {moment(image.createdAt).utc().format("DD MMM YYYY, HH:mm")} UTC
              </p>
            </div>
            <div className="meta-item">
              <p className="meta-title">Date captured</p>
              <p className="meta-value">
                {image.capturedAt
                  ? moment(image.capturedAt).utc().format("DD MMM YYYY, HH:mm") + " UTC"
                  : "N/A"}
              </p>
            </div>
            {image.dueDate && (
              <div className="meta-item">
                <p className="meta-title">Due date</p>
                <p className="meta-value">
                  {moment(image.dueDate).utc().format("DD MMM YYYY, HH:mm")} UTC
                </p>
              </div>
            )}
            <div className="meta-item">
              <p className="meta-title">Source</p>
              <p className="meta-value">{image.source || "N/A"}</p>
            </div>
            <div className="meta-item">
              <p className="meta-title">Message</p>
              <p className="meta-value">{image.message || "N/A"}</p>
            </div>
            <div className="meta-item">
              <p className="meta-title">Title</p>
              <p className="meta-value">{image.instructionsTitle || "N/A"}</p>
            </div>
            <div className="meta-item">
              <p className="meta-title">Instruction</p>
              <p className="meta-value">{image.instructions || "N/A"}</p>
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UploadModal;