import { Body } from "../../../../components/main-body/body";
import { useMemo, useState } from "react";
import moment from "moment-timezone";
import WeekPlan from "./week-plan";
import TaskDrawer from "../tasks/task-drawer";
import DropdownFilters from "../../../../components/dropdown-filters/dropdown-filters";

const Plan = ({ subjectId, startedAt, timezone }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [task, setTask] = useState();
  const [filters, setFilters] = useState({});

  const updateFilterValue = (value) => setFilters(value);

  const weeks = useMemo(() => {
    if (!startedAt || !timezone) return [];

    const startOfFirstWeek = moment.tz(startedAt, timezone).startOf("week");
    const now = moment.tz(timezone).endOf("week");
    const weeksCount = Math.ceil(now.diff(startOfFirstWeek, "weeks", true));

    const weekArray = [];

    for (let i = 0; i < weeksCount; i++) {
      const startDate = startOfFirstWeek.clone().add(i, "weeks");
      const endDate = startDate.clone().endOf("week");
      weekArray.unshift({
        weekIndex: i,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      });
    }

    return weekArray;
  }, [startedAt, timezone]);

  const openTask = (item) => {
    setTask(item);
    setIsOpenDrawer(true);
  };

  const close = () => {
    setIsOpenDrawer(false);
  };

  const weekOptions = weeks.map(week => ({
    id: week.weekIndex,
    name: 'Week ' + (week.weekIndex + 1)
  }));

  const statusesList = [
    { name: "Complete", id: 'COMPLETED'},
    { name: "Dismissed", id: 'DISSMISED'},
    { name: "Pending", id: 'PENDING'},
    { name: "Incomplete", id: 'INCOMPLETED'},
  ]

  return (
    <Body
      header={{
        title: "Plan",
      }}
      filters={
        <DropdownFilters
        filtersBy={[
          { name: "Week", id: "weekIndex", children: weekOptions },
          { name: "Status", id: "status", children: statusesList }
        ]}
        updateFilterValue={updateFilterValue}
        />
      }
      scroll
    >
      <div className="space-y-4">
        {(Array.isArray(filters.weekIndex) && filters.weekIndex.length > 0
          ? weeks.filter((week) => filters.weekIndex.includes(week.weekIndex))
          : weeks
        ).map((week) => (
          <WeekPlan
            key={`${week.weekIndex}-${(filters.status || []).join(',')}`}
            weekIndex={week.weekIndex}
            startDate={week.startDate}
            endDate={week.endDate}
            subjectId={subjectId}
            timezone={timezone}
            openTask={openTask}
            weekTotal={weeks.length}
            filters={filters}
          />
        ))}
      </div>
      <TaskDrawer isOpenDrawer={isOpenDrawer} close={close} task={task} />
    </Body>
  );
};

export default Plan;
