import { useEffect, useMemo, useState } from "react";
import { DrawerHeader } from "../../../../components/drawer/drawer-header";
import { Drawer } from "../../../../components/drawer/drawer";
import { Form } from "antd";
import {
  FormHeaderTextarea,
  FormImage,
  FormSelect,
  FormSwitch,
  FormInput
} from "../../../../components/form/form";
import { Editor } from "../../../../components/editor/editor";
import TagsSelect from "../../../../components/tags-select/tags-select";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../store/slices/user";
import ApiArticles from "../../../../api/articles";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ApiUserUploads from "../../../../api/user-uploads";

export const ArticleModal = ({
  form,
  data,
  onClose,
  onSave,
  tags,
  categories,
  articleId,
  updateHasImage,
  updateIsUpdatedImage,
  isLoading,
  authors = [],
  mode
}) => {
  const [editMode, setEditMode] = useState(false);
  const [editorContent, setEditorContent] = useState();
  const [articleImage, setArticleImage] = useState();
  const [isExternal, setIsExternal] = useState(data?.external ?? false);
  const navigate = useNavigate();
  const currentUser = useSelector(selectUser);

  const urlPart = "/administration/articles"

  useEffect(() => {
    if (mode === "edit") {
      setEditMode(true);
    }

    if (mode === "create") {
      setEditMode(true);
    }

    if (mode === "show") {
      setEditMode(false);
    }
  }, [mode]);

  const closeHandler = () => {
    navigate(urlPart);
    setEditMode(false);
    onClose();
  };

  const editHandler = () => {
    navigate(`${urlPart}/${articleId}/edit`);
    setEditMode(true);
  };

  const saveHandler = async () => {
    await onSave()?.finally?.(() => {
      setEditMode(false);
      if (articleId) {
        navigate(`${urlPart}/${articleId}`);
      } else {
        navigate(`${urlPart}`);
      }
    });
  };

  const cancelHandler = () => {
    if (articleId) {
      navigate(`${urlPart}/${articleId}`);
    } else {
      navigate(`${urlPart}`);
    }

    form.resetFields();
    setEditMode(false);
    if (data.status === "draft") {
      onClose();
    }
  };

  const onHandleContentUpdate = (value) => {
    setEditorContent(value);
  };

  const onHandleUpdateImage = (image) => {
    setArticleImage(image);
    form.setFieldValue(
      "image",
      image
    )
  };

  useEffect(() => {
    if (data?.status === "draft") {
      setEditMode(true);
    }

    if (data?.content) {
      setEditorContent(data.text);
    }

    if (data) {
      setArticleImage(data.image);
      setIsExternal(data?.external);
    }
  }, [data]);

  const updateArticleTags = (values) => {
    form.setFieldValue("tags", values);
  };

  const updateCategory = (category) => {
    form.setFieldValue("categoryId", category);
  };

  const updateImageStatus = (value) => {
    updateHasImage(value);
  };

  const updateAuthor = (author) => {
    form.setFieldValue(
      "userId",
      authors.find((a) => a.fullName === author)?.id
    );
  };

  const updateIsExternal = (value) => {
    form.setFieldValue("external", value)
    setIsExternal(value);
  };

  // async function fetchUpdatedImageUrls(content) {
  //   const imgRegex = /<img([^>]*?)title=["']([^"']+)["']([^>]*?)src=["']([^"']+)["']([^>]*)>/gi;
  //   const imageTitles = [];
  //   let match;

  //   while ((match = imgRegex.exec(content)) !== null) {
  //     imageTitles.push(match[2]);
  //   }

  //   if (imageTitles.length === 0) return content;

  //   try {
  //     const response = await ApiUserUploads.getSignedUrls({ imageIds: imageTitles });
  //     const urlMap = response.data;

  //     return content.replace(imgRegex, (match, beforeTitle, title, afterTitle, src, afterSrc) => {
  //       if (urlMap[title]) {
  //         return `<img${beforeTitle}title="${title}"${afterTitle}src="${urlMap[title]}"${afterSrc}>`;
  //       }
  //       return match;
  //     });

  //   } catch (error) {
  //     console.error("❌ Failed to refresh image URLs:", error);
  //     return content;
  //   }
  // }

  async function fetchUpdatedImageUrls(content) {
    const imgMarkdownRegex = /!\[(image-[a-f0-9-]+)\]\((.*?)\)/gi;
    const imgHtmlRegex = /<img[^>]*?alt=["'](image-[a-f0-9-]+)["'][^>]*?src=["'](.*?)["'][^>]*?>/gi;
    
    const imageIds = new Set();
    let match;

    while ((match = imgMarkdownRegex.exec(content)) !== null) {
      imageIds.add(match[1].replace("image-", ""));
    }
    while ((match = imgHtmlRegex.exec(content)) !== null) {
      imageIds.add(match[1].replace("image-", ""));
    }
    console.log('imageIds', imageIds)
    if (imageIds.size === 0) return content;
   
    try {
      const response = await ApiUserUploads.getSignedUrls({ imageIds: [...imageIds] });
      const urlMap = response.data;
      console.log('urlMap', urlMap)
      return content
        .replace(imgMarkdownRegex, (match, alt, src) => {
          console.log('imgMarkdownRegex', match)
          const imageId = alt.replace("image-", "");
          return urlMap[imageId] ? `![${alt}](${urlMap[imageId]})` : match;
        })
        .replace(imgHtmlRegex, (match, alt, src) => {
          console.log('imgHtmlRegex', match)
          const imageId = alt.replace("image-", "");
          return urlMap[imageId] ? `<img alt="${alt}" src="${urlMap[imageId]}" />` : match;
        });

    } catch (error) {
      console.error("❌ Failed to refresh image URLs:", error);
      return content;
    }
  }

  useEffect(() => {
    if (mode === "create") {
      setEditorContent("");
    }
  }, [mode]);

  useEffect(() => {
    if (data?.text) {
      fetchUpdatedImageUrls(data.text).then((updatedContent) => {
        setEditorContent(updatedContent);
      });
    }
  }, [data?.text]);

  const convertHtmlToMarkdown = (htmlContent) => {
    if (!htmlContent) return "";

    const imageRegex = /<img[^>]*src=["']([^"']+)["'][^>]*alt=["']([^"']*)["'][^>]*>|<img[^>]*alt=["']([^"']*)["'][^>]*src=["']([^"']+)["'][^>]*>/gi;

    return htmlContent.replace(imageRegex, (match, src1, alt1, alt2, src2) => {
      const src = src1 || src2 || '';
      const alt = alt1 || alt2 || '';
      return `<img src="${src}" alt="${alt}">`;
    });
  };

  const drawerContent = useMemo(() => {
    return data ? (
      <Form form={form}>
        <DrawerHeader
          title={
            <Form.Item name={"title"} initialValue={data.title}>
              <FormHeaderTextarea readonly={!editMode} placeholder={"Title"} />
            </Form.Item>
          }
          onEdit={editHandler}
          onSave={saveHandler}
          onCancel={cancelHandler}
          onClose={closeHandler}
          isEditing={editMode}
          isLoading={isLoading}
        />

        <Form.Item name={"categoryId"} initialValue={data.categories}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Category: </span>
          </div>
          <FormSelect
            options={categories.map((c) => ({
              value: c.id,
              label: c.name,
              key: c.id,
            }))}
            defaultValue={categories
              .map((cat) => ({value: cat.id, key: cat.id, label: cat.name}))
              .find((c) => c.value === data.categoryId)}
            onSelect={updateCategory}
            placeholder={editMode && "Select category..."}
            style={{minWidth: 160}}
            bordered={false}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"tags"} initialValue={data.tags}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Tags: </span>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <TagsSelect
              options={tags}
              defaultValue={data.tags}
              onChange={updateArticleTags}
              onCreate={updateArticleTags}
              readonly={!editMode}
              placeholder="+ Add Tag"
              isEditing={true}
            />
          </div>
        </Form.Item>
        <Form.Item
          name={"userId"}
          initialValue={authors.find((a) => a.id === data.userId)?.id}
        >
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Author: </span>
          </div>
          <FormSelect
            placeholder={editMode && "Select author"}
            options={authors?.map((author) => ({
              id: author.id,
              value: author.fullName,
            }))}
            defaultValue={() => {
              const usr =
                authors.find((a) => a.id === data.userId) || currentUser;
              return usr ? {id: usr?.id, value: usr?.fullName} : null;
            }}
            onSelect={updateAuthor}
            readonly={!editMode}
          />
        </Form.Item>
        <Form.Item name={"link"} initialValue={data.link}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">Source URL: </span>
          </div>
          <FormInput
            defaultValue={data.link}
            readonly={!editMode}
            placeholder={editMode ? 'Source URL' : ''}
            onChange={(e) => {
              form.setFieldValue("link", e.target.value)
            }}
          />
        </Form.Item>
        <Form.Item name={"external"} initialValue={isExternal}>
          <div className="article-modal-tags-label-wrapper">
            <span className="article-modal-tags-label">External Article: </span>
          </div>
          <FormSwitch
            key={'ext-key ' + isExternal}
            readOnly={!editMode}
            checked={isExternal}
            onChange={updateIsExternal}
          />
        </Form.Item>
        <Form.Item name={"image"} initialValue={articleImage}>
          <FormImage
            readonly={!editMode}
            onChange={onHandleUpdateImage}
            articleId={articleId}
            updateImageStatus={updateImageStatus}
            updateIsUpdatedImage={updateIsUpdatedImage}
            fetchIconApi={ApiArticles.fetchIcon}
            form={form}
            fetchFromFormKeyAutorized="external"
            fetchFromFormKeyUrl="link"
          />
        </Form.Item>
        {!isExternal ? (
          // <Form.Item name={"text"} initialValue={editorContent}>
          //   {editMode ? (
          //     <Editor
          //       content={editorContent || data.text}
          //       readonly={!editMode}
          //       onChange={onHandleContentUpdate}
          //       articleId={articleId}
          //     />
          //   ) : (
          //     <Markdown className="markdown-editor" rehypePlugins={[rehypeRaw]}>
          //       {convertHtmlToMarkdown(editorContent || data.text)}
          //     </Markdown>
          //   )}
          // </Form.Item>
          <Form.Item name={"text"} initialValue={editorContent}>
            {editMode ? (
              <Editor
                content={editorContent || data.text}
                readonly={!editMode}
                onChange={onHandleContentUpdate}
                articleId={articleId}
              />
            ) : (
              <Markdown className="markdown-editor" rehypePlugins={[rehypeRaw]}>
                {convertHtmlToMarkdown(editorContent || data.text)}
              </Markdown>
            )}
          </Form.Item>
          ) : null}
      </Form>
    ) : null;
  }, [data, articleImage, editMode, editorContent, isExternal]);

  return (
    <Drawer open={!!data} onClose={closeHandler} destoyOnClose={true}>
      {drawerContent}
    </Drawer>
  );
};
