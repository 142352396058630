import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, Button, message } from "antd";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_IMAGE_COMMAND, TooltipWrap } from "@mdxeditor/editor";
import ApiUserUploads from "../../api/user-uploads";

const ImageUpload = ({ articleId }) => {
  const [editor] = useLexicalComposerContext();

  const uploadImage = async (file) => {
    console.log("📤 Uploading image...");
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async () => {
      console.log("✅ File loaded into memory");
      const base64 = reader.result.split(",")[1];
      const mimeType = file.type;

      try {
        const response = await ApiUserUploads.uploadImage({ articleId, base64, mimeType });
        console.log("📸 Image uploaded:", response);

        editor.update(() => {
          const imageParams = {
            src: response.data.signedUrl,
            altText: `image-${response.data.userUpload?.id}`,
            title: `Image ${response.data.userUpload?.id}`,
            width: "100%",
            height: "auto",
          };

          if (response.data.userUpload?.id) {
            imageParams.id = response.data.userUpload.id;
            imageParams["data-id"] = response.data.userUpload.id;
          }
          editor.dispatchCommand(INSERT_IMAGE_COMMAND, imageParams);
        });

      } catch (error) {
        console.error("❌ Upload failed:", error);
        message.error("Upload failed");
      }
    };

    reader.onerror = () => {
      message.error("Failed to read file");
    };
  };

  return (
    <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
      <TooltipWrap title="Upload image">
        <Upload 
          beforeUpload={(file) => {
            uploadImage(file);
            return false;
          }}
          showUploadList={false}
        >
          <Button icon={<UploadOutlined />} />
        </Upload>
      </TooltipWrap>
    </div>
  );
};

export default ImageUpload;