import TagCardView from "./view";
import TagCardEdit from "./edit";
import ModalWrapper from "../../../../components/modal/modal";
import { useTranslation } from "react-i18next";

const TagCard = ({
  tag,
  isOpen,
  close,
  editMode,
  createTag,
  updateTag,
  deleteTag,
  toEditMode,
  form,
}) => {
  const { t } = useTranslation();

  const create = () => {
    const { name, description } = form.getFieldsValue();
    if (!tag.id) {
      createTag({
        name,
        description,
      });
    } else {
      updateTag(tag.id, {
        ...tag,
        name,
        description,
      });
    }
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={tag.id ? "Tag" : "Create tag"}
      onOk={create}
      onCancel={close}
      okText={t(
        tag.id ? "tags.modal.update" : "tags.modal.create"
      )}
      okButtonProps={{
        size: "large",
        style: { display: editMode ? "" : "none" },
      }}
      cancelButtonProps={{
        size: "large",
        style: {
          backgroundColor: "rgba(108, 108, 137, 0.08)",
          display: editMode ? "" : "none",
        },
      }}
    >
      {editMode ? (
        <TagCardEdit
          task={tag}
          createTag={createTag}
          close={close}
          updateTag={updateTag}
          deleteTag={deleteTag}
          form={form}
        />
      ) : (
        <TagCardView
          tag={tag}
          close={close}
          toEditMode={toEditMode}
          deleteTag={deleteTag}
          form={form}
        />
      )}
    </ModalWrapper>
  );
};

export default TagCard;
