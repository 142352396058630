import editIcon from "../../../../assets/edit.svg";
import { Divider, Form } from "antd";
// import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import "../../../../components/modals/invitations/styles.css";
import { useTranslation } from "react-i18next";

// const CollapseExtraItem = ({ tagId, onClick, remove, tagName }) => {
//   const items = [
//     {
//       key: "1",
//       label: "Delete",
//       icon: <DeleteOutlined />,
//       onClick: () => remove(tagId, tagName),
//     },
//   ];

//   return (
//     <Dropdown
//       menu={{
//         items,
//         onClick,
//       }}
//       trigger={["click"]}
//       overlayStyle={{ width: 120 }}
//     >
//       <EllipsisOutlined
//         style={{ cursor: "pointer", width: 30 }}
//         onClick={(e) => {
//           e.stopPropagation();
//         }}
//       />
//     </Dropdown>
//   );
// };

const TagCardView = ({ tag, toEditMode, deleteTag, form }) => {
  const { name, description } = tag || {};
  const { t } = useTranslation();

  return (
    <div
      className="tags-modal"
      style={{ position: "relative", bottom: 16 }}
    >
      <Divider />
      <div className="task-drawer__header pl11">
        <div />
        <div className="tags__modal-header__right">
{/*          <CollapseExtraItem
            tagName={tag.name}
            tagId={tag.id}
            remove={deleteTag}
          />*/}
          <img src={editIcon} alt="edit" onClick={toEditMode} />
        </div>
      </div>
      <Form form={form}>
        <Form.Item key={"name"} name={"name"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`tags.modal.name`)}
            </p>
            <p className="tags-drawer__body__title">{name}</p>
          </div>
        </Form.Item>
        <Form.Item key={"description"} name={"description"}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`tags.modal.description`)}
            </p>
            <p className="tags-drawer__body__description">
              {description}
            </p>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default TagCardView;
