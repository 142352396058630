import { Form } from "antd";
import { FormSwitch, FormFieldName } from "../../../../components/form/form";

const SelfAssignedSwitch = ({ control, editMode }) => {
  return (
    <Form.Item name="selfAssigned">
      <FormFieldName text="Self assigned:" />
      <FormSwitch
        checked={control.selfAssigned.value}
        onChange={editMode ? control.selfAssigned.change : undefined}
        disabled={!editMode}
      />
    </Form.Item>
  );
};

export default SelfAssignedSwitch;