import { useTranslation } from "react-i18next";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import TableСomponent from "../../../../components/table/table";
import moment from "moment";
import { CheckOutlined } from "@ant-design/icons";
import { useState } from "react";
import "../../../../components/icons/styles.css";
import TaskDrawer from "./task-drawer";
import encodeSvgWithColor from "../../../../common/utils/encodeSvgWithColor";

const TasksTable = ({ tasks }) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [task, setTask] = useState();
  const { t } = useTranslation();
  const columns = [
    {
      title: t("subject_management.table.task_name"),
      dataIndex: "name",
      render: (_, task) => {
        const { backgroundColor, avatar, avatarColor, title } =
          task?.DailyTask || {};

        return (
          <div className="tasks-table__name-container">
            <div
              className="icon-wrapper"
              style={{
                backgroundColor,
              }}
            >
              {avatar && (
                <img
                  src={encodeSvgWithColor(avatar, avatarColor)}
                  alt="avatar"
                />
              )}
            </div>
            <p>{title}</p>
          </div>
        );
      },
      key: "id",
      width: "30%",
    },
    {
      title: t("subject_management.table.survey"),
      dataIndex: "survey",
      render: (_, task) => (
        <CheckOutlined
          style={{
            color:
              task.status === "COMPLETED" ? "black" : "rgba(178, 178, 199, 1)",
          }}
          alt="check"
        />
      ),
      key: "survey",
      width: "10%",
    },
    {
      title: t("subject_management.table.due_date"),
      dataIndex: "dueDate",
      render: (_, task) => (
        <span>
          {`${moment(task.dueDate)
            .utc()
            .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
        </span>
      ),
      key: "due",
      width: "20%",
    },
    {
      title: t("subject_management.table.completion_date"),
      dataIndex: "complete",
      render: (_, task) => (
        <span>
          {task.status === "COMPLETED"
            ? `${moment(task.completedAt)
                .utc()
                .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"
            : "N/A"}
        </span>
      ),
      key: "completion",
      width: "20%",
    },
    {
      title: t("subject_management.table.status"),
      dataIndex: "status",
      render: (_, user) => (
        <UserActivityStatus userStatus={user.status || "PENDING"} />
      ),
      key: "status",
      width: "20%",
    },
  ];

  const openSurvey = (item) => {
    setTask(item);
    setIsOpenDrawer(true);
  };

  const close = () => {
    setIsOpenDrawer(false);
  };

  return (
    <>
      <TableСomponent
        columns={columns}
        data={tasks}
        onHandleRowClick={openSurvey}
      />
      <TaskDrawer isOpenDrawer={isOpenDrawer} close={close} task={task} />
    </>
  );
};

export default TasksTable;
