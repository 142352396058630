import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getTags = (query) => axios.get(toQueryString("api/v1/tag-managements?", query));

// const addTag = (tag) => axios.post("api/v1/tag-managements", tag);

const updateTag = (id, tag) =>
  axios.put(`api/v1/tag-managements/${id}`, tag);

// const deleteTag = (id) => axios.delete(`api/v1/tag-managements/${id}`);

const ApiTagManagement = {
  getTags,
  // addTag,
  updateTag,
  // deleteTag,
};

export default ApiTagManagement;
