import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/card/card";
import PlanCalendar from "./calendar";
import { Button, Modal, Spin, Tabs, Dropdown, Menu } from "antd";
import type { TabsProps } from 'antd';
import { Icon } from "../../../../components/icon/icon";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import TagsSelect from "../../../../components/tags-select/tags-select";
import ApiPlanManagement from "../../../../api/plan-management";
import { LoadingOutlined } from "@ant-design/icons";

import {
  FormSelect,
} from "../../../../components/form/form";

const SELECT_COMPLETION_OPTIONS = [
  { key: "reStart", value: "Re-start" },
  { key: "repeatLastWeek", value: "Repeat last week " },
]

const DiscardModalComponent = ({
  title,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Discard changes"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  );
};

const RemoveModalComponent = ({
  isModalOpen,
  handleOk,
  handleCancel,
  title,
}) => (
  <Modal
    title={title}
    open={isModalOpen}
    onOk={handleOk}
    onCancel={handleCancel}
    okButtonProps={{ danger: true }}
    okText="Remove"
    cancelText="Cancel"
    className="plan-page-action-modal"
  >
    <p>All items mapped to the removed week will disappear from the plan</p>
  </Modal>
);

const normalizeGroups = (group = []) => {
  return group.map((cohort) => ({
    id: cohort.id,
    label: cohort.EnrollmentFieldOption.value,
  }));
};

const MAX_WIDTH = 600;
const MIN_WIDTH = 150;

const PlanPage = ({
  plan,
  cohorts,
  scenarios,
  toList,
  refreshPlan,
  openNotification,
}) => {
  const { t } = useTranslation();
  const { title, id } = plan || {};
  const [completionLogic, setCompletionLogic] = useState("repeatLastWeek");
  const [planTasks, setPlanTasks] = useState([]);
  const [planScenarios, setPlanScenarios] = useState([]);
  // const [weekIndex, setWeekIndex] = useState(0);
  const [versionStatus, setVersionStatus] = useState(null);
  // const [weeklyScheduleId, setWeeklyScheduleId] = useState();
  const [planActiveCohortsIds, setPlanActiveCohortsIds] = useState([]);
  const [isOpenDiscardModal, setOpenDiscardModal] = useState();
  const [isLoading, setLoading] = useState(true);
  const [inProcess, setProcess] = useState(false);
  const [activeWeek, setActiveWeek] = useState(0);
  const [weeklySchedules, setWeeklySchedules] = useState([]);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [weekToRemove, setWeekToRemove] = useState(null);

  const [titleValue, setTitleValue] = useState(title);
  const titleRef = useRef(null);
  const [titleWidth, setTitleWidth] = useState(MIN_WIDTH);

  const updateWidth = () => {
    if (titleRef.current) {
      requestAnimationFrame(() => {
        const newWidth = Math.min(
          Math.max(titleRef.current.scrollWidth + 10, MIN_WIDTH),
          MAX_WIDTH
        );
        setTitleWidth(newWidth);
      });
    }
  };

  useEffect(() => {
    setTimeout(updateWidth, 0);
  }, []);

  useEffect(() => {
    updateWidth();
  }, [titleValue]);

  useEffect(() => {
    if (weeklySchedules.length > 0) {
      setCompletionLogic(weeklySchedules[activeWeek]?.completionLogic || "reStart");
    }
  }, [weeklySchedules, activeWeek]);

  const updateCompletionLogic = (value) => {
    setCompletionLogic(value);
    ApiPlanManagement.updatePlanLogic(plan.id, { completionLogic: value })
      .then(() => {
        refreshPlan();
      })
      .catch((error) => {
        console.error("Error updating completion logic:", error);
      });
  };

  useEffect(() => {
    let { WeeklySchedules } = plan;
    WeeklySchedules = WeeklySchedules?.filter(
      (ws) => ws.status === "pending" || ws.status === "activeCopy"
    ).sort((a, b) => a.weekIndex - b.weekIndex);
    setWeeklySchedules(WeeklySchedules);
    const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];
    const allTasks = WeeklySchedules.flatMap((ws) =>
      (ws.tasks || []).map((task) => ({ ...task, weekIndex: ws.weekIndex }))
    );

    const sortedAllTasks = allTasks.sort((a, b) => {
    const aHasRemind = Number.isInteger(a.remindHour);
    const bHasRemind = Number.isInteger(b.remindHour);

    const totalDaysInPlan = (weeklySchedules.length || WeeklySchedules.length) * 7;

    const getAdjustedStart = (task) => {
      const startDayAbsolute = task.weekIndex * 7 + DAYS.indexOf(task.day);
      const endDayAbsolute = startDayAbsolute + task.duration;

      if (endDayAbsolute > totalDaysInPlan) {
        return { weekIndex: 0, dayIndex: 0 };
      }
      return { weekIndex: task.weekIndex, dayIndex: DAYS.indexOf(task.day) };
    };

    const aAdjusted = getAdjustedStart(a);
    const bAdjusted = getAdjustedStart(b);

    if (!aHasRemind && !bHasRemind) {
      if (aAdjusted.weekIndex !== bAdjusted.weekIndex) {
        return aAdjusted.weekIndex - bAdjusted.weekIndex;
      }
      if (aAdjusted.dayIndex !== bAdjusted.dayIndex) {
        return aAdjusted.dayIndex - bAdjusted.dayIndex;
      }
      return b.duration - a.duration;
    }

    if (!aHasRemind) return -1;
    if (!bHasRemind) return 1;

    return (a.remindHour - b.remindHour) || (a.remindMinute - b.remindMinute);
    });

    let weekTaskLevels = {};
    const updatedTasks = sortedAllTasks.flatMap((task) => {
      const startWeek = Number.isInteger(task.weekIndex) ? task.weekIndex : 0;
      const startDayIndex = DAYS.indexOf(task.day);
      const totalDays = task.duration;
      const totalWeeks = (weeklySchedules.length || WeeklySchedules.length);

      let tasksWithDays = [];
      let seenDays = new Set();

      let taskLevel = 0;
      let isConflict = true;

      while (isConflict) {
        isConflict = false;
        
        let occupiedLevels = new Set();

        for (let i = 0; i < totalDays; i++) {
          let currentDayIndex = (startDayIndex + i) % 7;
          let weekOffset = Math.floor((startDayIndex + i) / 7);
          let targetWeek = (startWeek + weekOffset) % totalWeeks;

          if (!weekTaskLevels[targetWeek]) {
            weekTaskLevels[targetWeek] = {};
          }
          if (!weekTaskLevels[targetWeek][currentDayIndex]) {
            weekTaskLevels[targetWeek][currentDayIndex] = [];
          }

          occupiedLevels = new Set([
            ...occupiedLevels,
            ...weekTaskLevels[targetWeek][currentDayIndex]
          ]);
        }

        if (occupiedLevels.has(taskLevel)) {
          taskLevel++;
          isConflict = true;
        }
      }

      console.log(`✅ Final Task Level for ${task.id}: ${taskLevel}`);

      for (let i = 0; i < totalDays; i++) {
        let currentDayIndex = (startDayIndex + i) % 7;
        let weekOffset = Math.floor((startDayIndex + i) / 7);
        let targetWeek = (startWeek + weekOffset) % totalWeeks;
        let key = `${targetWeek}-${currentDayIndex}`;

        if (i >= totalWeeks * 7) break;
        if (!Number.isInteger(targetWeek) || !Number.isInteger(currentDayIndex)) {
          console.error(`❌ Error ${task.id}: targetWeek=${targetWeek}, dayIndex=${currentDayIndex}`);
          continue;
        }

        if (!weekTaskLevels[targetWeek][currentDayIndex]) {
          weekTaskLevels[targetWeek][currentDayIndex] = [];
        }

        weekTaskLevels[targetWeek][currentDayIndex].push(taskLevel);

        let existingTask = tasksWithDays.find(
          (t) => t.id === task.id && t.weekIndex === targetWeek && t.day === DAYS[currentDayIndex]
        );

        if (existingTask) {
          existingTask.duration = totalDays;
        } else {
          const isContinued = startWeek !== targetWeek && i === 0;
          tasksWithDays.push({
            ...task,
            day: DAYS[currentDayIndex],
            weekIndex: targetWeek,
            position: taskLevel,
            originalDay: task.originalDay || task.day,
            originalWeek: task.originalWeek || task.weekIndex,
            duration: totalDays,
            isContinued,
          });

          seenDays.add(key);
        }
      }

      return tasksWithDays.filter((t) => t.weekIndex === activeWeek);
    });

    setPlanTasks(updatedTasks.sort((a, b) => a.position - b.position));
    const status = WeeklySchedules.some(schedule => schedule.status === 'pending') 
      ? 'pending' :
      plan.status === 'pending' ? 'pending'
      : 'activeCopy';
    setVersionStatus(status);

    const { scenarios } = WeeklySchedules?.[activeWeek] || {};
    setPlanScenarios(scenarios?.sort((a, b) => a.position - b.position));
    setPlanActiveCohortsIds(WeeklySchedules?.[0].group || []);
    // setWeeklyScheduleId(id);
    if (plan) {
      setLoading(false);
    }
  }, [plan, activeWeek, weeklySchedules.length]);

  const onBack = () => {
    toList();
  };

  const addTaskToPlan = (task) => {
    setPlanTasks((planTasks) => [...planTasks, task]);
  };

  const addScenarioToPlan = (scenario) => {
    setPlanScenarios([...planScenarios, scenario]);
  };

  const updatePlanTitle = (e) => {
    const { value } = e.target || {};
    setTitleValue(value);
    ApiPlanManagement.updatePlan(id, { title: value });
  };

  const updatePlanCohorts = (value) => {
    const newCohortsList = value.map((v) => v.id);
    setPlanActiveCohortsIds(newCohortsList);
    ApiPlanManagement.updatePlanCohort(plan.id, {
      groupScheduleIds: newCohortsList,
    }).finally(() => {
      refreshPlan();
    });
  };

  const updateTaskPlan = (plan) => {
    setPlanTasks(plan);
  };

  const updateScenariosPlan = (plan) => {
    setPlanScenarios(plan);
  };

  const discardPlan = () => {
    ApiPlanManagement.discardPlan(plan.id).then(() => {
      refreshPlan();
    });
    openNotification({
      type: "success",
      message: <p>{t("plan_management.notification.discard")}</p>,
    });
  };

  const addWeek = () => {
    ApiPlanManagement.addWeek(plan.id).then(() => {
      refreshPlan();
    });
  };

  const activatePlan = () => {
    setProcess(true);
    if (!inProcess) {
      ApiPlanManagement.activatePlan(plan.id)
        .then(() => {
          refreshPlan();

          openNotification({
            type: "success",
            message: <p>{t("plan_management.notification.activated")}</p>,
          });
        })
        .catch((e) => {
          if (e?.response?.data?.error === "GroupsAlreadyUsed") {
            const cohorts = e?.response?.data?.groupsAlreadyUsed.reduce(
              (acc, cohort) => `${acc} ${cohort.EnrollmentFieldOption.value}`,
              ""
            );

            const plansTitles = e?.response?.data?.plans.reduce(
              (acc, plan) => `${acc} ${plan.title}`,
              ""
            );

            openNotification({
              type: "error",
              message: (
                <p>
                  {t("plan_management.notification.errorGroups", {
                    cohorts,
                    plans: plansTitles,
                  })}
                </p>
              ),
            });
          }
        })
        .finally(() => {
          setProcess(false);
        });
    }
  };

  const openDiscardModal = () => {
    setOpenDiscardModal(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
  };

  const handleDiscardOk = () => {
    discardPlan();
    closeDiscardModal();
  };

  const handleDiscardCancel = () => {
    closeDiscardModal();
  };

  const onChangeWeek = (key) => {
    const selectedWeek = weeklySchedules.find((week) => week.id === key);
    const index = selectedWeek.weekIndex
    setActiveWeek(index);
    refreshPlan();
  };

  const onAddTab = (key: string) => {
    addWeek();
  };

  const items: TabsProps['items'] = weeklySchedules.map((schedule) => ({
    key: schedule.id,
    label: `Week ${schedule.weekIndex + 1}`,
  }));

  const handleMoveLeftWeek = (id) => {
    let weekIndex = items.findIndex(item => item.key === id);
    if (weekIndex > 0) {
      weekIndex -= 1
    }
    ApiPlanManagement.reorderWeek(id, weekIndex).then(() => {
      refreshPlan();
      setActiveWeek(weekIndex);
    });
    console.log("Move left action triggered");
  };


  const handleMoveRightWeek = (id) => {
    let weekIndex = items.findIndex(item => item.key === id);
    if (weekIndex < items.length - 1) {
      weekIndex += 1
    }
    ApiPlanManagement.reorderWeek(id, weekIndex).then(() => {
      refreshPlan();
      setActiveWeek(weekIndex);
    });
    console.log("Move right action triggered");
  };

  const handleDuplicateWeek = (id) => {
    ApiPlanManagement.duplicateWeek(id).then(() => {
      refreshPlan();
    });
    console.log("Duplicate action triggered");
  };

  const openRemoveModal = (id) => {
    setWeekToRemove(id);
    setRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
    setWeekToRemove(null);
  };

  const handleRemoveConfirm = () => {
    const selectedWeek = weeklySchedules.find((week) => week.id === weekToRemove);
    const index = selectedWeek.weekIndex;
    
    if (index + 1 === weeklySchedules.length) {
      setActiveWeek(index - 1);
    }
    
    ApiPlanManagement.deleteWeek(weekToRemove).then(() => {
      refreshPlan();
      closeRemoveModal();
    });
    console.log("Remove action triggered");
  };

  const renderTabLabel = (label, id) => {
    const isOnlyOneWeek = weeklySchedules.length === 1;
    let index = weeklySchedules.findIndex( x => x.id === id)
    const isFirst = index === 0
    const isLast = index === weeklySchedules.length - 1
    const menu = (
      <Menu>
        <Menu.Item key="moveLeft" onClick={() => handleMoveLeftWeek(id)} disabled={isFirst}>{t("plan_management.tabs.move_left")}</Menu.Item>
        <Menu.Item key="moveRight" onClick={() => handleMoveRightWeek(id)} disabled={isLast}>{t("plan_management.tabs.move_right")}</Menu.Item>
        <Menu.Item key="duplicate" onClick={() => handleDuplicateWeek(id)}>{t("plan_management.tabs.duplicate")}</Menu.Item>
        <Menu.Item key="remove" onClick={() => openRemoveModal(id)} disabled={isOnlyOneWeek}>
          {t("plan_management.tabs.remove")}
        </Menu.Item>
      </Menu>
    );

    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <Dropdown overlay={menu} trigger={['click']}>
          <span
            style={{
              marginLeft: 8,
              fontSize: 20,
              fontWeight: 'bold',
              cursor: 'pointer',
              lineHeight: 1
            }}
          >
            ⋮
          </span>
        </Dropdown>
      </span>
    );
  };

  const tabItems = items.map((item) => ({
    ...item,
    label: renderTabLabel(item.label, item.key),
  }));

  const selectedWeekNumber = () => {
    const selectedWeek = weeklySchedules.find((week) => week.id === weekToRemove);
    const index = selectedWeek?.weekIndex;
    return index + 1
  };

  return (
    <div className="plan-page">
      {isLoading ? (
        <div className="plan-page-loading-box">
          <Spin
            className="plan-page-loading"
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        </div>
      ) : (
        <>
          <div className="plan-page-header">
            <div className="plan-page-header-right">
              <Button
                icon={<Icon name={"arrow-left"} />}
                style={{ marginRight: 16 }}
                onClick={onBack}
              />
              <div className="plan-page-title-container">
                <input
                  ref={titleRef}
                  type="text"
                  value={titleValue}
                  onChange={updatePlanTitle}
                  className="plan-page-name"
                  style={{
                    width: `${titleWidth}px`,
                    maxWidth: `${MAX_WIDTH}px`,
                    minWidth: `${MIN_WIDTH}px`,
                  }}
                />
              </div>
            </div>
            <div className="plan-page-header-left">
              {versionStatus === "pending" ? (
                <>
                  <Button
                    onClick={openDiscardModal}
                    className="plan-page-header-left-btn"
                    disabled={inProcess}
                  >
                    {t("plan_management.discard_changes")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={activatePlan}
                    className="plan-page-header-left-btn"
                    loading={inProcess}
                  >
                    {plan.status === "active" || plan.status === "pending"
                      ? t("plan_management.save")
                      : t("plan_management.activate")}
                  </Button>
                  <DiscardModalComponent
                    isModalOpen={isOpenDiscardModal}
                    handleOk={handleDiscardOk}
                    handleCancel={handleDiscardCancel}
                    title={"Discard changes"}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="plan-info">
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.table.cohort")}
              </p>
              <TagsSelect
                defaultValue={[
                  ...cohorts,
                  ...normalizeGroups(plan.activeGroups),
                ].filter((cohort) => planActiveCohortsIds?.includes(cohort.id))}
                options={[...cohorts, ...normalizeGroups(plan.activeGroups)]}
                onChange={updatePlanCohorts}
                selectPlaceholder="Select cohort"
              />
            </div>
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.status")}
              </p>
              <UserActivityStatus userStatus={plan.status} fontSize={14} />
            </div>
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.completion_logic")}
              </p>
              <FormSelect
                bordered={false}
                options={SELECT_COMPLETION_OPTIONS}
                style={{ width: 180 }}
                placeholder="Select"
                value={SELECT_COMPLETION_OPTIONS.find(x => x.key === completionLogic)?.value} // Показує значення
                onChange={(selectedValue) => {
                  const selectedKey = SELECT_COMPLETION_OPTIONS.find(option => option.value === selectedValue)?.key;
                  if (selectedKey) {
                    updateCompletionLogic(selectedKey);
                  }
                }}
              />
            </div>
          </div>
          <div>
            <Card>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Tabs 
                  defaultActiveKey={activeWeek} 
                  items={tabItems} 
                  onChange={onChangeWeek}
                  style={{ flexGrow: 1 }}
                  tabBarExtraContent={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginLeft: 8 }}>
                        <Button 
                          type="text" 
                          style={{ 
                            padding: '4px 12px',
                            color: '#595959', 
                            fontSize: 14 
                          }}
                          onClick={onAddTab}
                        >
                          + Add
                        </Button>
                      </div>
                    </div>
                  }
                />
              </div>
              <PlanCalendar
                planId={plan.id}
                planTasks={planTasks}
                addTaskToPlan={addTaskToPlan}
                updateTaskPlan={updateTaskPlan}
                planScenarios={planScenarios}
                scenariosList={scenarios}
                addScenarioToPlan={addScenarioToPlan}
                updateScenariosPlan={updateScenariosPlan}
                refreshPlan={refreshPlan}
                weekIndex={activeWeek}
                weeklySchedules={weeklySchedules}
              />
            </Card>
          </div>
          <RemoveModalComponent
            isModalOpen={isRemoveModalOpen}
            handleOk={handleRemoveConfirm}
            handleCancel={closeRemoveModal}
            title={`Are you sure you want to remove the "Week ${selectedWeekNumber()}"?`}
          />
        </>
      )}
    </div>
  );
};

export default PlanPage;
