import { ReactComponent as ChechboxIcon } from "../../assets/checkbox.svg";
import "./styles.css";

const ValidationChecklist = ({
  validationRules,
  errorFields = [],
  touched,
  varificationValue,
}) => {
  const listItems = validationRules.map((rule, index) => {
    const { text, invalidColor, validColorCheckbox, validColorText } = rule;

    const isValid =
      varificationValue &&
      varificationValue.length &&
      touched &&
      !errorFields.includes(text);

    const checkboxColor = isValid ? validColorCheckbox : invalidColor;
    const textColor = isValid ? validColorText : invalidColor;

    return (
      <div key={`${text}-${index}`} className="checklist-item">
        <ChechboxIcon style={{ color: checkboxColor }} />
        <span style={{ color: textColor }}>{text}</span>
      </div>
    );
  });

  return listItems;
};

export default ValidationChecklist;
