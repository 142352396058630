import { Progress } from "antd";
import "../styles.css";
import SurveyTable from "./survey-table";
import Block, { BlockSection } from "../../../../components/block/block";
import { Body } from "../../../../components/main-body/body";

const twoColors = {
  "0%": "#2762f5",
  "100%": "rgba(54, 179, 126, 1)",
};

const twoColorsDone = {
  "0%": "rgba(54, 179, 126, 1)",
  "100%": "rgba(54, 179, 126, 1)",
};

const Surveys = ({ survey = {} }) => {
  const { rate, completed, total, subjectSurveys } = survey;

  return (
    <Body header={{ title: "Onboarding" }} scroll>
      <Block fixThead>
        <BlockSection>
          <div className="survey__items-container">
            <div className="profile_form-item survey__item">
              <Progress
                type="circle"
                percent={rate * 100}
                strokeColor={rate === "1.00" ? twoColorsDone : twoColors}
                format={() => ""}
                // size="small"
                size={56}
                strokeWidth={20}
              />
              <div>
                <p>{rate * 100}%</p>
                <p className="survey__item__description">
                  Completion rate
                </p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{completed}</p>
                <p className="survey__item__description">Total completed</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{total}</p>
                <p className="survey__item__description">Total assigned</p>
              </div>
            </div>
          </div>
        </BlockSection>
        <SurveyTable surveys={subjectSurveys} />
      </Block>
    </Body>
  );
};

export default Surveys;
