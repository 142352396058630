import { useState } from "react";

const useSearch = () => {
  const [search, setSearch] = useState(null);

  const handleUpdateSearchValue = (input) => {
    let value;

    if (input && typeof input === "object" && input.target) {
      value = input.target.value;
    } else {
      value = input;
    }

    console.log("HANDLE handleUpdateSearchValue", value);

    setSearch(value && value.trim().length ? value : null);
  };

  return { search, handleUpdateSearchValue };
};

export default useSearch;