import { useTranslation } from "react-i18next";
import moment from "moment";
import { useMemo, useState } from "react";
import DeleteModal from "../common/delete-modal";
import ActionsDropdown from "../common/actions-dropdown";
import InfinityTable from "../../../../../components/infinity-table/infinity-table";
import { TableCell } from "../../../../../components/table/table-cell/table-cell";
import { TableCellPicture } from "../../../../../components/table/table-cell/table-cell-picture";

export const AdminResourceTable = ({
  data,
  shouldDropCache,
  onHandleRowClick,
  remove,
  removeModalQuestion,
  tableComlumnKeys = ["title", "updatedAt"],
  isLoading,
  updateSorting
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState();
  const [item, setItem] = useState();

  const handleOpenDeleteModal = (item) => {
    setModalOpen(true);
    setItem(item);
  };

  const handleCloseDeleteModal = () => {
    setModalOpen(false);
    setItem();
  };

  const handleOkPress = (id, articleTitle) => {
    handleCloseDeleteModal();
    remove(id, articleTitle);
  };

  const getColumnByKey = (key, { shouldDropCache }) => {
    switch (key) {
      case "cover":
        return {
          title: "Cover",
          dataIndex: "cover",
          key: "cover",
          render: (_, item) => (
            <TableCellPicture proportion={60} pageId={item.id} shouldDropCache={shouldDropCache} />
          ),
          width: "200px",
        };

      case "categories":
        return {
          title: "Categories",
          dataIndex: "categories",
          key: "categories",
          render: (_, item) => <TableCell text={item?.Category?.name} />,
          width: "15%",
          sorter: true,
        };

      case "title":
        return {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (_, item) => (
            <TableCell title={item.title || item.name || item.text} />
          ),
          width: "30%",
          sorter: true,
        };

      case "titleColorMark":
        return {
          title: "Title",
          dataIndex: "title",
          key: "title",
          render: (_, item) => (
            <TableCell
              title={item?.title || item?.name || item?.text}
              markColor={item?.color || "#28282866"}
              subtext={item?.FactUrl?.title || item?.FactCitation?.source}
            />
          ),
          width: "30%",
        };

      case "updatedAt":
        return {
          title: t("task_management.table.last_modified"),
          dataIndex: "updatedAt",
          render: (_, item) => (
            <span>
              {`${moment(item?.updatedAt)
                .utc()
                .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"}
            </span>
          ),
          key: "updatedAt",
          width: "20%",
          sorter: true,
        };
      case "action":
        return {
          title: "Action",
          dataIndex: "action",
          render: (_, item) => (
            <ActionsDropdown
              acrticle={item?.id}
              remove={() => handleOpenDeleteModal(item)}
            />
          ),
          key: "action",
          width: "10%",
        };

      default:
        break;
    }
  };

  const tableColumns = useMemo(() => {
    return tableComlumnKeys.map((key) => getColumnByKey(key, { shouldDropCache }));
  }, [tableComlumnKeys, handleCloseDeleteModal, handleOpenDeleteModal]);

  const onChange = (pagination, filters, sorter) => {
    const sortField = sorter.field;
    let sortOrder;

    if (!sorter.order) {
      sortOrder = "ASC";
    } else {
      sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
    }
    updateSorting(sortField, sortOrder);
  };

  return (
    <>
      <DeleteModal
        title={item?.title}
        question={removeModalQuestion}
        handleOk={() => handleOkPress(item?.id, item?.title)}
        handleCancel={handleCloseDeleteModal}
        isModalOpen={isModalOpen}
      />
      <InfinityTable
        data={data}
        tableColumns={tableColumns}
        onHandleRowClick={onHandleRowClick}
        isLoading={isLoading}
        onChange={onChange}
      />
    </>
  );
};

export default AdminResourceTable;
