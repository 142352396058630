import {
  MDXEditor,
  headingsPlugin,
  linkPlugin,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  CreateLink,
  ListsToggle,
  listsPlugin,
  linkDialogPlugin,
  quotePlugin,
  markdownShortcutPlugin,
  thematicBreakPlugin,
  InsertThematicBreak,
  imagePlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import "./editor.css";
import classNames from "classnames";
import ImageUpload from "./ImageUpload";

export const Editor = ({ content, readonly, onChange, imageUpdateHandler, articleId }) => {
  return (
    <MDXEditor
      className={classNames("lf-editor", { "lf-editor__readonly": readonly })}
      markdown={content || ""}
      onChange={onChange}
      plugins={[
        headingsPlugin(),
        linkPlugin(),
        listsPlugin(),
        quotePlugin(),
        markdownShortcutPlugin(),
        thematicBreakPlugin(),
        linkDialogPlugin(),
        imagePlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              <BoldItalicUnderlineToggles />
              <ListsToggle />
              <CreateLink />
              <InsertThematicBreak />
              <ImageUpload articleId={articleId} />
            </>
          ),
        }),
      ]}
    />
  );
};
