import { Divider, Form, Input, Select } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../styles.css";
import ApiUsersManagement from "../../../../api/user-manager";
import useNotification from "../../../notification/notification";
import ModalWrapper from "../../../modal/modal";
import { selectIsSystemAdmin } from "../../../../store/slices/user";

const InviteUserModal = ({ isOpen, close }) => {
  const isSystemAdmin = useSelector(selectIsSystemAdmin);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [userRole, setUserRole] = useState("user");
  const { openNotification } = useNotification();
  const [inProgress, setProgress] = useState(false);

  const onClose = () => {
    close();
    form.resetFields();
  };

  const inviteUser = () => {
    const newUser = form.getFieldsValue();

    setProgress(true);

    ApiUsersManagement.invite({ ...newUser, role: userRole })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("invite_user.notification.success", { email: newUser.email })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `invite_user.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      })
      .finally(() => {
        setProgress(false);
        onClose();
      });
  };

  const getRoleOptions = () => {
    const roles = [
      { label: "User", value: "user" },
      { label: "Administrator", value: "admin" },
    ];

    if (isSystemAdmin) {
      roles.push({ label: "System Administrator", value: "systemAdmin" });
    }

    return roles;
  };

  const roleOptions = getRoleOptions();

  const formItems = ["firstName", "lastName", "email", "role"].map((key) => {
    if (key === "role") {
      return (
        <Form.Item key={key} name={key}>
          <div className="invite-user__field">
            <p className="invite-user__field__name">
              {t(`invite_user.${key}`)}
            </p>
            <Select
              bordered={false}
              options={roleOptions}
              defaultValue={roleOptions[0]}
              onSelect={(role) => setUserRole(role)}
            />
          </div>
        </Form.Item>
      );
    }
    return (
      <Form.Item key={key} name={key}>
        <div className="invite-user__field">
          <p className="invite-user__field__name">{t(`invite_user.${key}`)}</p>
          <Input
            bordered={false}
            placeholder={t(`invite_user.placeholders.${key}`)}
            maxLength={1000}
          />
        </div>
      </Form.Item>
    );
  });

  return (
    <ModalWrapper
      isOpen={isOpen}
      title={t("invite_user.title")}
      onOk={() => inviteUser()}
      onCancel={onClose}
      okText={t("invite_user.invite")}
      okButtonProps={{
        size: "large",
      }}
      cancelButtonProps={{
        size: "large",
        style: { backgroundColor: "rgba(108, 108, 137, 0.08)" },
      }}
      confirmLoading={inProgress}
    >
      <Divider />

      <Form form={form}>{formItems}</Form>

      <Divider />
    </ModalWrapper>
  );
};

export default InviteUserModal;
