import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import moment from "moment-timezone";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/card/card";
import PageContentLoader from "../../../../components/loader/page-content-loader";
import completedIcon from "../../../../assets/completed.svg";
import disabledIcon from "../../../../assets/disabled.svg";
const WeekPlan = ({ weekIndex, startDate, endDate, subjectId, timezone, openTask, weekTotal, filters }) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (inView) {
      fetchWeekTasks();
    }
  }, [inView]);

  const fetchWeekTasks = async () => {
    setLoading(true);
    try {
      const params = {
        startDate,
        endDate,
        ...filters
      }
      const res = await ApiSubjectsManagement.getSubjectPlan(subjectId, params)
      setTasks(res.data);
    } finally {
      setLoading(false);
    }
  };

  const start = moment(startDate);
  const end = moment(endDate);

  const sameMonth = start.month() === end.month() && start.year() === end.year();
  const sameYear = start.year() === end.year();

  let formattedRange;

  if (sameMonth && sameYear) {
    formattedRange = `${start.format("D")} – ${end.format("D MMM")}`;
  } else if (sameYear) {
    formattedRange = `${start.format("D MMM")} – ${end.format("D MMM")}`;
  } else {
    formattedRange = `${start.format("D MMM YYYY")} – ${end.format("D MMM YYYY")}`;
  }

  let formattedWeek = `${weekIndex+1}/${weekTotal}`;

  const DAYS = ["su", "mo", "tu", "we", "th", "fr", "sa"];

  const isMultiDayTask = (task) => {
    if (!task?.plannedAt || !task?.dueDate) return false;

    const planned = moment.utc(task.plannedAt).tz(timezone);
    const due = moment.utc(task.dueDate).tz(timezone);
    return !planned.isSame(due, 'day');
  };

  const isMultiStart = (task, currentDate) => {
    if (!task?.plannedAt) return false;
    if (!isMultiDayTask(task)) return false

    const planned = moment.utc(task.plannedAt).tz(timezone);
    return planned.isSame(currentDate, 'day');
  };

  const isMultiEnd = (task, currentDate) => {
    if (!task?.dueDate) return false;
    if (!isMultiDayTask(task)) return false

    const due = moment.utc(task.dueDate).tz(timezone);
    return due.isSame(currentDate, 'day');
  };

  const isMultiMiddle = (task, currentDate) => {
    if (!task?.plannedAt || !task?.dueDate) return false;
    const start = moment.utc(task.plannedAt).tz(timezone);
    const end = moment.utc(task.dueDate).tz(timezone);
    return currentDate.isAfter(start, 'day') && currentDate.isBefore(end, 'day');
  };

  const isMultiFromPrevious = (task, currentDate, columnIndex) => {
    if (!task?.plannedAt) return false;

    const start = moment.utc(task.plannedAt).tz(timezone);
    return columnIndex === 0 && start.isBefore(currentDate, 'day');
  };

  const isMultiToNext = (task, currentDate, columnIndex) => {
    if (!task?.dueDate) return false;

    const end = moment.utc(task.dueDate).tz(timezone);
    return columnIndex === 6 && end.isAfter(currentDate, 'day');
  };

  const getColumnSpan = (task, currentDate, columnIndex) => {
    if (!task?.plannedAt || !task?.dueDate) return undefined;

    const start = moment.utc(task.plannedAt).tz(timezone);
    const end = moment.utc(task.dueDate).tz(timezone);

    if (start.isSame(currentDate, 'day') && end.diff(start, 'days') > 0) {
      const span = Math.min(7 - columnIndex, end.diff(currentDate, 'days') + 1);
      return `span ${span}`;
    }

    if (columnIndex === 0 && start.isBefore(currentDate, 'day') && end.isAfter(currentDate, 'day')) {
      const span = Math.min(7, end.diff(currentDate, 'days') + 1);
      return `span ${span}`;
    }

    return undefined;
  };

  const darkenColor = (hex) => {
    try {
      const c = hex.substring(1);
      const num = parseInt(c, 16);
      const r = Math.floor((num >> 16) * 0.4);
      const g = Math.floor(((num >> 8) & 0xFF) * 0.4);
      const b = Math.floor((num & 0xFF) * 0.4);
      return `rgb(${r}, ${g}, ${b})`;
    } catch {
      return "#333";
    }
  };

  const getWeekDates = (startDate, timezone) => {
    const start = new Date(startDate);
    return Array.from({ length: 7 }, (_, i) => {
      const day = new Date(start);
      day.setDate(start.getDate() + i);
      return moment.tz(day.toISOString(), timezone).startOf('day');
    });
  };
  const weekDates = getWeekDates(startDate, timezone);

  return (
    <div className={"plan-calendar"} ref={ref}>
      <Card>
        <div
          className={"plan-calendar-title"}
          style={{ gap: 8, display: "flex", alignItems: "center" }}
        >
          <b>Week {weekIndex + 1}</b>{" "}
          <span className={"plan-calendar-period"}>{formattedRange}</span>
          <span className={"plan-calendar-week"}>{formattedWeek}</span>
        </div>

        <div className="calendar">
          <div className="calendar-grid calendar-header">
            {DAYS.map((day, index) => (
              <div
                key={index}
                className={classNames("calendar-day calendar-columns-items")}
              >
                {t(`days.${day}`)}
              </div>
            ))}
          </div>

          {loading && (
            <div className="calendar-loader-overlay">
              <PageContentLoader backgroundOpacity={0.5} />
            </div>
          )}

          {!loading && tasks.week && (
            <div className="calendar-grid calendar-body">
              {tasks.week.map((row, rowIndex) =>
                row.map((task, colIndex) => {
                  const currentDate = weekDates[colIndex];
                  return (
                    <div
                      key={`cell-${rowIndex}-${colIndex}`}
                      className="calendar-cell"
                    >
                      {task ? (
                        <div
                          className={classNames("task", {
                            "task--pending": task.status === "INCOMPLETED" || task.status === "PENDING",
                            "task--completed": task.status === "COMPLETED",
                            "task--dismissed": task.status === "DISMISSED",
                            "task--multi": isMultiDayTask(task),
                            "task--multi-start": isMultiStart(task, currentDate),
                            "task--multi-middle": isMultiMiddle(task, currentDate),
                            "task--multi-end": isMultiEnd(task, currentDate),
                            "task--from-previous": isMultiFromPrevious(task, currentDate, colIndex),
                            "task--to-next": isMultiToNext(task, currentDate, colIndex),
                          })}
                          style={{
                            backgroundColor:
                              (task.status === "PENDING")
                                ? task.DailyTask.backgroundColor
                                : "transparent",
                            borderColor:
                              (task.status !== "PENDING")
                                ? task.DailyTask.backgroundColor
                                : "transparent",
                            gridColumn: getColumnSpan(task, currentDate, colIndex),
                          }}
                          onClick={() => openTask(task)}
                        >
                          {(!isMultiDayTask(task) || isMultiStart(task, currentDate) || isMultiFromPrevious(task, currentDate, colIndex)) && (
                            <>
                              <div
                                className="task-title"
                                style={{
                                  color: darkenColor(task.DailyTask.backgroundColor),
                                }}
                              >
                                {task.DailyTask.title}
                              </div>
                              {task.status === "COMPLETED" && (
                                <div
                                  className="task-icon"
                                  style={{
                                    backgroundColor: task.DailyTask.backgroundColor,
                                    color: darkenColor(task.DailyTask.backgroundColor),
                                  }}
                                >
                                  <img src={completedIcon} alt="Completed icon" />
                                </div>
                              )}
                              {task.status === "DISMISSED" && (
                                <div className="task-icon dismissed">
                                  <img src={disabledIcon} alt="Disabled icon" />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="task empty" />
                      )}
                    </div>
                  );
                })
              )}
            </div>
          )}

          {!loading &&
            Array.isArray(tasks.week) &&
            tasks.week.flat().every((cell) => !cell) && (
              <div></div>
            )}
        </div>
      </Card>
    </div>
  );
};

export default WeekPlan;
