import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ApiSubjectsManagement from "../../api/subjects-manager";

const useSubjects = (search, filters) => {
  const { hash } = useLocation();

  const [subjects, setSubjects] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const [isLoading, setLoading] = useState(false);

  const fetchSubjects = (params) => {
    setLoading(true);

    let status;
    switch (hash) {
      case "#disabled":
        status = "inactive";
        break;
      case "#pending":
        status = ["invited", "inviteError"];
        break;
      default:
        break;
    }

    params = { ...params, ...filters, search, status };

    ApiSubjectsManagement.getSubjects(params)
      .then((r) => {
        const { subjects, hasMore } = r.data;
        setSubjects(subjects);
        setHasMore(hasMore);
      })
      .catch(() => {
        console.log("Subjects management fetch users error.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSubjects();
  }, [hash, search, filters]);

  return { subjects, hasMore, isLoading, fetchSubjects };
};

export default useSubjects;
