import axios from "axios";

const uploadImage = (params) => axios.post(`/api/v1/pages/images`, params);

const deleteImage = (articleId, id, params) => axios.post(`/api/v1/pages/${articleId}/images/${id}`, params);

const getSignedUrls = (params) => axios.post(`/api/v1/user-uploads/get-signed-urls`, params);

const ApiUserUploads = {
  uploadImage,
  deleteImage,
  getSignedUrls
};

export default ApiUserUploads;
