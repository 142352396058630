import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Input, Modal, Button, Form } from "antd";
import { Icon } from "../../../../components/icon/icon";
import ScenarioEditorWrapper from "./scenario-editor";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import debounce from "../../../../common/utils/debounce";
import TagsSelect from "../../../../components/tags-select/tags-select";
import axios from "axios";
import ApiLifeFacts from "../../../../api/life-facts";
import ApiArticles from "../../../../api/articles";
import ApiScenariosManagement from '../../../../api/scenarios-management';
import ApiTaskManagement from '../../../../api/task-managements';
import {
  FormSelect,
} from "../../../../components/form/form";

const DiscardModalComponent = ({
  title,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Discard changes"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  );
};

const normalizeGroups = (group) => {
  return group.map((cohort) => ({
    id: cohort.id,
    label: cohort.EnrollmentFieldOption.value,
  }));
};

const ScenarioPage = ({
  scenario = {},
  toList,
  refreshScenario,
  openNotification,
  api,
  cohorts = [],
  isOnboarding,
  tasks,
  authors,
  updateScenarioAuthor
}) => {

  const [form] = Form.useForm();
  const [isCheckingChanges, setIsCheckingChanges] = useState(false);
  const [showChangesButtons, setShowChangesButtons] = useState(false);

  const ScenarioEditModal = ({
    isOpen,
    onCancel,
    scenarioEditButtonRef,
    authors,
    scenario
  }) => {
    const [selectedAuthor, setSelectedAuthor] = useState(
      scenario.ScenarioVersion.userId
    );

    useEffect(() => {
      setSelectedAuthor(scenario.ScenarioVersion.userId);
      // if (scenario.ScenarioVersion?.status === 'pending') {
      //   setShowChangesButtons(true)
      // }
    }, [scenario]);

  useEffect(() => {
      // setIsCheckingChanges(false)
  }, [scenario?.ScenarioVersion?.status]);

    const handleAuthorChange = (authorId) => {
      setSelectedAuthor(authorId);
      updateAuthor(authorId);
    };
    return (
      <Modal
        visible={isOpen}
        onCancel={onCancel}
        footer={null}
        mask={false}
        getContainer={false}
        closable={false}
        style={{
          position: "absolute",
          top: scenarioEditButtonRef?.current?.getBoundingClientRect().bottom + 5 + "px",
          left: scenarioEditButtonRef?.current?.getBoundingClientRect().left + "px",
        }}
      >
        <Form.Item
          name={"userId"}
          initialValue={selectedAuthor}
        >
          <span className="article-modal-tags-label">Author: </span>
          <FormSelect
            placeholder={"Select author"}
            options={authors?.map((author) => ({
              value: author.id,
              label: author.fullName,
            }))}
            value={authors.find((a) => a.id === selectedAuthor)?.id}
            onSelect={(authorId) => handleAuthorChange(authorId)}
          />
        </Form.Item>
      </Modal>
    );
  };

  const updateAuthor = (authorId) => {
    form.setFieldValue("userId", authorId);
    setShowChangesButtons(true)
    updateScenarioAuthor(scenario.ScenarioVersion.id, authorId);
  };

  const { t } = useTranslation();
  const [isOpenDiscardModal, setOpenDiscardModal] = useState(false);
  const [title, setTitle] = useState(scenario.title ?? "Test title");
  const [showSimulator, setShowSimulator] = useState(false);
  const [drawScenario, setDrawScenario] = useState();
  const [scenarioCohortsIds, setScenarioCohortsIds] = useState(
    scenario?.OnboardingSurveyVersion?.group
  );
  const [isLoading, setLoading] = useState();
  const [isOpenActivityModal, setOpenActivityModal] = useState(false);
  const scenarioEditButtonRef = useRef(null);

  const openActivityModal = () => {
    setOpenActivityModal(true);
  }

  const closeActivityModal = () => {
    setOpenActivityModal(false);
    refreshScenario()
  };

  const onBack = () => {
    console.log(isLoading);
    toList();
  };

  const updateScenario = (key, value) => {
    setLoading(true);
    api
      .updateScenario(scenario.id, { [key]: value })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: scenario.value,
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (title && title !== scenario.name && title !== "") {
      debounce(updateScenario("title", title), 350);
    }
  }, [title]);

  const updateScenarioTitle = (e) => {
    setTitle(e.target.value);
  };

  const discardScenario = () => {
    setIsCheckingChanges(true);
    setLoading(true);
    api
      .discardScenarioChanges(scenario.ScenarioVersion.id)
      .then(() => {
        openNotification({
          type: "success",
          message: <p>{t("scenario_management.notification.discard")}</p>,
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: <p>{t("scenario_management.notification.discard_error")}</p>,
        });
      })
      .finally(async () => {
        setLoading(false);
        // setDrawScenario();
        await refreshScenario();
        setShowChangesButtons(false);
        setIsCheckingChanges(false);
      });
  };

  const activateScenario = () => {
    setIsCheckingChanges(true);
    setLoading(true);

    api
      .activateScenario(scenario.ScenarioVersion.id)
      .then(() => {
        openNotification({
          type: "success",
          message: <p>{t("scenario_management.notification.activated")}</p>,
        });
        // refreshScenario();
      })
      .catch((e) => {
        console.log("activateScenario error: ", e);
        openNotification({
          type: "error",
          message: (
            <p>{t("scenario_management.notification.activated_error")}</p>
          ),
        });
      })
      .finally(async () => {
        setDrawScenario();
        await refreshScenario();
        setLoading(false);
        setShowChangesButtons(false);
        setIsCheckingChanges(false);
      });
  };

  const getAlternativeMessages = (
    originalMessage,
    alreadyGeneratedMessages
  ) => {
    if (api.getAlternativeMessages) {
      return api.getAlternativeMessages(
        originalMessage,
        alreadyGeneratedMessages
      );
    } else {
      return axios.post(`api/v1/scenario-managements/alternative-messages`, {
        originalMessage,
        alreadyGeneratedMessages,
      });
    }
  };

  const getAllMessages = () => {
    return api.getAllMessages();
  };

  const getOptionsByMessage = (message) => {
    return api.getOptionsByMessage(message);
  };

  const getAllFitFacts = async () => {
    const data = await Promise.all([
      ApiLifeFacts.get(),
      ApiLifeFacts.getTags(),
    ]);

    const fitFacts = data[0].data.facts.map((fact) => ({
      id: fact.id,
      text: fact.text,
      itemUrl: {
        url: fact.FactUrl?.url,
        source: fact.FactUrl?.source,
        title: fact.FactUrl?.title,
        id: fact.FactUrl?.id,
      },
    }));
    const categories = data[0].data.categories.map((category) => ({
      id: category.id,
      name: category.name,
      description: category.description,
    }));
    const tags = data[1].data.tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
    }));

    return {
      data: {
        ids: fitFacts,
        categories,
        tags: tags.length ? tags : [{ id: 0, name: "No tags" }],
      },
    };
  };

  const getAllArticles = async () => {
    const data = await Promise.all([
      ApiArticles.get(),
      ApiArticles.getCategories(),
      ApiArticles.getTags(),
    ]);


    const articles = data[0].data.pages.map((article) => ({
      id: article.id,
      text: article.title,
      itemUrl: {
        url: article.FactUrl?.url,
        source: article.FactUrl?.source,
        title: article.FactUrl?.title,
        id: article.FactUrl?.id,
      },
    }));
    const categories = data[1].data.categories.map((category) => ({
      id: category.id,
      name: category.name,
      description: category.description,
    }));
    const tags = data[2].data.tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
    }));

    return {
      data: {
        ids: articles,
        categories,
        tags: tags.length ? tags : [{ id: 0, name: "No tags" }],
      },
    };
  };

  const getFilteredFitFacts = async (tags, categories) => {
    const data = await ApiLifeFacts.get({
      tags,
      categoryIds: categories,
    });
    const fitFacts = data.data.facts.map((fact) => ({
      id: fact.id,
      text: fact.text,
      itemUrl: {
        url: fact.FactUrl?.url,
        source: fact.FactUrl?.source,
        title: fact.FactUrl?.title,
        id: fact.FactUrl?.id,
      },
    }));
    const appliedFilters = {
      tags,
      categories,
    };

    return {
      data: {
        ids: fitFacts,
        appliedFilters,
      },
    };
  };

  const getFilteredArticles = async (tag, categories) => {
    const data = await ApiArticles.get({
      tag,
      categoryIds: categories,
    });
    const articles = data.data.pages.map((article) => ({
      id: article.id,
      text: article.text,
      itemUrl: {
        url: article.FactUrl?.url,
        source: article.FactUrl?.source,
        title: article.FactUrl?.title,
        id: article.FactUrl?.id,
      },
    }));
    const appliedFilters = {
      tags: tag,
      categories,
    };

    return {
      data: {
        ids: articles,
        appliedFilters,
      },
    };
  };

  const getAllScenario = async () => {
    const data = await ApiScenariosManagement.getScenarios();
    const scenarios = data.data.scenarios.map((scenario) => ({
      id: scenario.id,
      name: scenario.title,
    }));

    return {
      data: scenarios,
    };
  };

  const getTaskManagements = async () => {
    const data = await ApiTaskManagement.getTasks();
    return {
      data: data.data.taskManagements,
    }
  };

  const openDiscardModal = () => {
    setOpenDiscardModal(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
  };

  const handleDiscardOk = () => {
    discardScenario();
    closeDiscardModal();
  };

  const handleDiscardCancel = () => {
    closeDiscardModal();
  };

  const handleToogleSimulator = () => {
    setShowSimulator(!showSimulator);
  };

  const updateDrawScenario = (editedScenario, validation) => {
    setLoading(true);

    const hasChanges =
      scenario.ScenarioVersion?.data !== JSON.stringify(editedScenario);

    const hasData = Object.keys(editedScenario).length;

    if (
      hasChanges &&
      hasData &&
      !validation.length &&
      scenario.ScenarioVersion?.id
    ) {
      if (scenario.ScenarioVersion?.status !== 'pending') {
        setShowChangesButtons(true)
      }
      setDrawScenario(editedScenario);

      api
        .updateScenarioVersion(scenario.ScenarioVersion?.id, {
          data: JSON.stringify(editedScenario),
        })
        .then(() => {
          refreshScenario();
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updateScenarioCohorts = (value) => {
    if (isOnboarding) {
      const valuesIds = value.map((v) => v.id);
      setScenarioCohortsIds(valuesIds);
      api
        .updateScenarioVersion(scenario.ScenarioVersion?.id, {
          group: valuesIds,
        })
        .then(() => {
          refreshScenario();
        });
    }
  };

  // const currentUser = useSelector(selectUser);

  return (
    <div className="scenario-page">
      <div className="scenario-page-header">
        <div className="scenario-page-header-right">
          <Button
            icon={<Icon name={"arrow-left"} />}
            style={{ marginRight: 16 }}
            onClick={onBack}
          />
          <Input
            value={title}
            bordered={false}
            onChange={updateScenarioTitle}
            className="scenario-page-name"
            style={{
              width: title?.length * 11 < 100 ? 100 : title?.length * 11,
            }}
          />
          <div style={{ marginRight: 10 }}>
            <UserActivityStatus
              userStatus={showChangesButtons ? 'pending' : scenario.status ? scenario.status : "inactive"}
              fontSize={14}
            />
          </div>
          {authors && authors.length > 0 && (
            <>
              <div className="scenario-page-edit-btn">
                <span style={{ fontWeight: 'bold', lineHeight: 1, cursor: 'pointer', fontSize: 20,display: 'flex', alignItems: 'center' }} ref={scenarioEditButtonRef} onClick={openActivityModal}>
                  ⋮
                </span>
              </div>
              <ScenarioEditModal
                isOpen={isOpenActivityModal}
                onCancel={closeActivityModal}
                scenarioEditButtonRef={scenarioEditButtonRef}
                authors={authors}
                scenario={scenario}
              />
            </>
          )}
          {isOnboarding && (
            <TagsSelect
              defaultValue={[
                ...cohorts,
                ...normalizeGroups(scenario.GroupOnboardingSurveys),
              ].filter((cohort) => scenarioCohortsIds.includes(cohort.id))}
              options={[
                ...cohorts,
                ...normalizeGroups(scenario.GroupOnboardingSurveys),
              ]}
              onChange={updateScenarioCohorts}
              selectPlaceholder="Select cohort"
            />
          )}
        </div>
        <div className="scenario-page-header-left">
          <Button
            className="scenario-page-header-left-btn scenario-page-run-btn"
            onClick={handleToogleSimulator}
            icon={<Icon name={showSimulator ? "stop" : "run"} />}
          >
            {showSimulator ? "Stop" : "Play"}
          </Button>
          {showChangesButtons || scenario.ScenarioVersion?.status === 'pending' ? (
            <>
              <Button
                onClick={openDiscardModal}
                className="scenario-page-header-left-btn"
                disabled={isCheckingChanges}
                loading={isCheckingChanges}
              >
                {t("scenario_management.discard_changes")}
              </Button>
              <Button
                type="primary"
                onClick={activateScenario}
                className="scenario-page-header-left-btn"
                disabled={isCheckingChanges}
                loading={isCheckingChanges}
              >
                {scenario?.status === "active" || scenario?.status === "pending"
                  ? t("scenario_management.save")
                  : t("scenario_management.activate")}
              </Button>
              <DiscardModalComponent
                isModalOpen={isOpenDiscardModal}
                handleOk={handleDiscardOk}
                handleCancel={handleDiscardCancel}
                title={"Discard changes"}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className="scenario-editor">
        <ScenarioEditorWrapper
          id={scenario.ScenarioVersion?.id}
          scenario={drawScenario ?? JSON.parse(scenario.ScenarioVersion?.data)}
          update={updateDrawScenario}
          scenarioFacts={scenario.facts}
          showSimulator={showSimulator}
          tasks={tasks}
          fetchers={{
            getAlternativeMessages,
            getAllMessages,
            getOptionsByMessage,
            getAllFitFacts,
            getAllArticles,
            getFilteredFitFacts,
            getFilteredArticles,
            getAllScenario,
            getTaskManagements,
          }}
        />
      </div>
    </div>
  );
};

export default ScenarioPage;
