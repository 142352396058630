import "./styles.css";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import useSearch from "../administration/user-management/useSearch";
import useSubjects from "./useSubjects";
import InviteSubjectModal from "../../components/modals/invitations/subject/invite-subject";
import SubjectDashboard from "./dashboard/subject-dashboard";
import SubjectsList from "./subjects-list/subjects-list";
import Uploads from "./uploads/uploads";
import { MainNavigation } from "../../components/main-navigation/main-navigation";
import { Sidebar } from "../../components/sidebar/sidebar";
import { MainLayoutV2 } from "../../components/main-layout-v2/main-layout-v2";
import { Body } from "../../components/main-body/body";
import { useSelector } from "react-redux";
import { selectIsSystemAdmin } from "../../store/slices/user";
import DropdownFilters from "../../components/dropdown-filters/dropdown-filters";
import ApiSubjectsDashboard from "../../api/subjects-dashboard";
import ApiSubjectsManagement from "../../api/subjects-manager";

const SubjectManagement = () => {
  const isSystemAdmin = useSelector(selectIsSystemAdmin);
  const { t } = useTranslation();
  const { hash } = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const [cohortsList, setCohortsList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const { search, handleUpdateSearchValue } = useSearch();
  const { subjects, hasMore, isLoading, fetchSubjects } = useSubjects(search, filters);


  const [isOpen, setOpen] = useState(false);

  const handleToogleModal = () => {
    if (isOpen) {
      fetchSubjects();
    }
    setOpen(!isOpen);
  };

  const getDashboardFilterItems = () => {
    ApiSubjectsDashboard.filterField().then((r) => {
      setCohortsList(r.data.cohorts?.map((c) => ({ id: c.id, name: c.value })));
    });
  };

  const fetchSubjectList = async () => {
    ApiSubjectsManagement.getSubjectsSimple().then((r) => {
      setSubjectList(
        r.data.subjects.map((s) => ({
          id: s.id,
          name: s.displayId,
        }))
      );
    });
  };

  const headerTitle = useMemo(() => {
    switch (hash) {
      default:
      case "#subjects":
        return "Subjects";
      case "#pending":
        return "Pending subjects";
      case "#disabled":
        return "Blocked subjects";
      case "#uploads":
        return "Uploads";
    }
  }, [hash, t]);

  const fetchEnrollInviteUserTemplate = () =>
    axios.get("api/v1/enrollment-fields/user");

  const leftSideNavItems = [
    { key: "/subject-management#subjects", label: "Subjects", default: true },
    { key: "/subject-management#pending", label: "Pending subjects" },
    { key: "/subject-management#disabled", label: "Blocked subjects" },
    { key: "/subject-management#uploads", label: "Uploads" },
  ];

  const headerProps = useMemo(
    () => ({
      title: headerTitle,
      search: { value: search, onHandleChange: handleUpdateSearchValue },
      create: {
        label: t("subject_management.btn.add"),
        onClick: handleToogleModal,
        renderModalComponent: (
          <InviteSubjectModal
            isOpen={isOpen}
            close={handleToogleModal}
            fetchEnrollInviteUserTemplate={fetchEnrollInviteUserTemplate}
            isSystemAdmin={isSystemAdmin}
          />
        ),
      },
    }),
    [headerTitle, search, isOpen, t]
  );

  useEffect(() => {
    fetchSubjectList();
    getDashboardFilterItems();
  }, []);

  const header = useMemo(() => {
    switch (hash) {
      case "#dashboard":
        return {};
      default:
        return headerProps;
    }
  }, [headerTitle, search, isOpen, hash, headerProps]);

  const openSubject = (subject) => {
    navigate(`/subject-management/subject/${subject.id}`);
  };

  const content = useMemo(() => {
    switch (hash) {
      case "#dashboard":
        return <SubjectDashboard />;
      default:
        return (
          <SubjectsList
            subjects={subjects}
            hasMore={hasMore}
            isLoading={isLoading}
            fetchSubjects={fetchSubjects}
            onHandleRowClick={openSubject}
          />
        );
    }
  }, [hash, subjects, hasMore, isLoading, fetchSubjects, openSubject]);

  const updateFilterValue = (value) => setFilters(value);

  const statuses = [
    { id: 'pending', name: 'Pending'},
    { id: 'confirmed', name: 'Active'},
    { id: 'disabled', name: 'Disabled'}
  ]

  const headerFilter = useMemo(
    () => (
      <DropdownFilters
        filtersBy={[
          { name: "Subject", id: "subjectIds", children: subjectList },
          { name: "Cohort", id: "cohortIds", children: cohortsList },
          { name: "Status", id: "statuses", children: statuses }
        ]}
        updateFilterValue={updateFilterValue}
      />
    ),
    [subjectList, cohortsList, statuses]
  );
  // const headerFilter = useMemo(
  //   () => (
  //     <DropdownFilters
  //       filtersBy={[
  //         { name: "Subject", id: "subjectIds", children: subjectList },
  //         { name: "Cohort", id: "cohortIds", children: cohortsList },
  //         { name: "Status", id: "statuses", children: statuses }
  //       ]}
  //       updateFilterValue={updateFilterValue}
  //     />
  //   ),
  //   []
  // );
  return (
    <MainLayoutV2>
      <MainNavigation />
      <Sidebar items={leftSideNavItems} title={t("subject_management.title")} />
      {hash === "#uploads" ? <Uploads /> : <Body filters={headerFilter} header={header}>{content}</Body>}
    </MainLayoutV2>
  );
};

export default SubjectManagement;
