import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import editIcon from "../../../../assets/edit.svg";
import "../styles.css";
import "../../../../components/profile/styles.css";
import { Button, Form, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import InviteContent from "../../../../components/modals/invitations/components/invite-content";
import axios from "axios";
import { useTranslation } from "react-i18next";
import useNotification from "../../../../components/notification/notification";
import ApiSubject from "../../../../api/subject";
import ApiSubjectsManagement from "../../../../api/subjects-manager";
import { Body } from "../../../../components/main-body/body";
import { useNavigate } from "react-router-dom";

const DeleteModalComponent = ({
  item,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={item.displayId}
      open={isModalOpen}
      onOk={() => {
        handleOk(item.id, item);
      }}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Delete"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this subject?</p>
    </Modal>
  );
};

const Profile = ({ subject, fetchSubject, isSystemAdmin, isAdmin }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const { status, invitedAt, email, enrollmentData, weekIndex, artificial } = subject;
  const [isEdit, setIsEdit] = useState(false);
  const [contentFields, setContentFields] = useState([]);
  const [selectsValues, setSelectsValues] = useState({});
  const [inputsValues, setInputsValues] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);
  const { openNotification } = useNotification();
  const navigate = useNavigate();

  const onModalSelectChange = (id, value) => {
    setHasChanges(true);
    if (id === 'weekIndex') {
      value = { [id]: value, subjectId: subject.id}
    }
    setSelectsValues({ ...selectsValues, [id]: value });
  };

  // const handleWeekChange = (event) => {
  //   const newWeekIndex = event.target.value;
  //   setSelectsValues((prev) => ({ ...prev, weekIndex: newWeekIndex }));
  //   setHasChanges(true);
  // };
  const mockWeeks = Array.from({ length: subject.weekCount }, (_, i) => ({ 
    label: `Week ${i + 1}`, 
    value: i,
    default: subject.weekIndex === i
  }));

  const enrollmentDataFields = useMemo(() => {
    return [
      ...enrollmentData
        .sort((a, b) => a.sort - b.sort)
        .map((data) => (
          <div
            key={data.label}
            className="profile_form-item"
            style={{ padding: "0px 24px " }}
          >
            <p>{data.label}</p>
            <span>{data.value}</span>
          </div>
        )),
      <div
        key="current-week"
        className="profile_form-item"
        style={{ padding: "0px 24px" }}
      >
        <p>Current week</p>
        <span>{subject.weekIndex !== undefined && subject.weekIndex >= 0
          ? `Week ${subject.weekIndex + 1}`
          : "No plan assigned"}
        </span>
      </div>
    ]
  }, [enrollmentData, isEdit]);

  useEffect(() => {
    fetchEnrollInviteUserTemplate();
  }, [subject]);

  // const fetchEnrollInviteUserTemplate = () => {
  //   axios.get("api/v1/enrollment-fields/user").then((r) => {
  //     const fields = r.data.enrollmentFields.map((field, index) => {
  //       const { value, enrollmentResponseId } = enrollmentData.filter(
  //         (data) => data.label === field.label
  //       )[0];

  //       return {
  //         ...field,
  //         defaultValue: value,
  //         id: subject.enrollmentResponses[index]?.id,
  //         enrollmentResponseId,
  //       };
  //     });
  //     // if (mockWeeks.length > 0) {
  //       fields.push({
  //         label: "Current week",
  //         type: "select",
  //         options: mockWeeks,
  //         defaultValue: weekIndex >= 0 ? weekIndex : 'No plan assigned',
  //         id: "weekIndex",
  //       });    
  //     // }
  //     setContentFields(fields);
  //   });
  // };

  const fetchEnrollInviteUserTemplate = () => {
    axios.get("api/v1/enrollment-fields/user").then((r) => {
      const fields = r.data.enrollmentFields.map((field, index) => {
        const matchingData = enrollmentData.find((data) => data.label === field.label);

        return {
          ...field,
          defaultValue: matchingData ? matchingData.value : undefined,
          id: subject.enrollmentResponses[index]?.id,
          enrollmentResponseId: matchingData ? matchingData.enrollmentResponseId : undefined,
        };
      });

      fields.push({
        label: "Current week",
        type: "select",
        options: mockWeeks,
        defaultValue: weekIndex >= 0 ? weekIndex : 'No plan assigned',
        id: "weekIndex",
      });

      setContentFields(fields);
    });
  };

  const toogleEdit = () => {
    if (isEdit) {
      setInputsValues({});
      setSelectsValues({});
    }

    setIsEdit(!isEdit);
  };

  const isDisabledUser = () => ["blocked", "inactive"].includes(subject.status);

  const isNotApprovedSubject = () =>
    ["invited", "inviteError"].includes(subject.status);

  const reinvite = () => {
    ApiSubject.reinvite(subject.id)
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>{t("profile.notification.reinvite.success", { email })}</p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.reinvite.error")}</p>,
        });
      });
  };

  const toogleUserStatus = () => {
    const { activate, deactivate } = ApiSubject;
    const isDisabled = isDisabledUser();
    const toogle = isDisabled ? activate(subject.id) : deactivate(subject.id);
    toogle
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t(
                `profile.notification.toogle_subject.${
                  isDisabled ? "activated" : "deactivated"
                }`,
                {
                  name: subject.displayId,
                }
              )}
            </p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.toogle_subject.error")}</p>,
        });
      })
      .finally(() => {
        fetchSubject();
      });
  };

  const handleFormChange = (data) => {
    setHasChanges(true);
    const { value, name } = data[0];
    setInputsValues({ ...inputsValues, [name[0]]: value });
  };

  const updateSubject = () => {
    const changedFormFields = { ...inputsValues, ...selectsValues };
    ApiSubjectsManagement.updateSubjectData(changedFormFields)
      .then((r) => {
        toogleEdit();
        setHasChanges(false);
        fetchSubject();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("subject_management.notification.profile.updated", {
                subject: subject.displayId,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `subject_management.notification.profile${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const openSubjectDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const closeSubjectDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteSubject = (id) => {
    ApiSubjectsManagement.deleteSubject(id)
      .then(() => {
        openNotification({
          type: "success",
          message: <p>{t("profile.notification.delete_subject.success")}</p>,
        });

        navigate("/subject-management#subjects");
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("profile.notification.delete_subject.error")}</p>,
        });
      });
  };

  useEffect(() => {
    fetchEnrollInviteUserTemplate();
  }, [subject]);

  return (
    <Body header={{ title: "Profile data" }} scroll>
      <div className="profile_form-container">
        <div className="profile_form-item" style={{ padding: "0px 24px " }}>
          <p>Status</p>
          <UserActivityStatus userStatus={status} />
        </div>
        <div className="profile_form-item" style={{ padding: "0px 24px " }}>
          <p>Registration date</p>
          <span>{moment(invitedAt).format("DD MMM YYYY")}</span>
        </div>
        {isSystemAdmin && (
          <div className="profile_form-item" style={{ padding: "0px 24px " }}>
            <p>Artificial</p>
            <span>{artificial ? "Yes" : "No"}</span>
          </div>
        )}
      </div>

      <div className="profile_form-container">
        <div className="profile_form__header">
          <span className="profile_form__header__title">Subject profile</span>
          <div>
            {isAdmin && (
              isEdit ? (
                <div className="profile-header__btns">
                  <Button
                    style={{
                      height: 40,
                      background: "rgba(108, 108, 137, 0.08)",
                    }}
                    onClick={toogleEdit}
                  >
                    {t("profile.btns.cancel")}
                  </Button>
                  <Button
                    type="primary"
                    style={{ marginLeft: 8, height: 40 }}
                    onClick={updateSubject}
                    disabled={!hasChanges}
                  >
                    {t("profile.btns.save")}
                  </Button>
                </div>
              ) : (
                <img
                  src={editIcon}
                  alt="edit"
                  role="presentation"
                  onClick={toogleEdit}
                />
              )
            )}
          </div>
        </div>
        {isAdmin && (
          <div className="profile_form-item" style={{ padding: "0px 24px " }}>
            <p>Email</p>
            <span>{email}</span>
          </div>
        )}
        <Form form={form} onFieldsChange={handleFormChange}>
          {isEdit ? (
            <InviteContent
              fields={contentFields}
              onSelectChange={onModalSelectChange}
            />
          ) : (
            enrollmentDataFields
          )}
        </Form>
      </div>
      {isAdmin && (
        <div className="profile_form-footer">
          {isNotApprovedSubject() && (
            <Button
              key="reinvite"
              type="primary"
              style={{ height: 40 }}
              onClick={reinvite}
            >
              {t("profile.form.reinvite_subject")}
            </Button>
          )}
          <Button
            key="toogleUser"
            type="secondary"
            style={{ background: "rgba(108, 108, 137, 0.08)", height: 40 }}
            onClick={toogleUserStatus}
          >
            {t(
              `profile.form.${
                isDisabledUser() ? "activate_subject" : "deactivate_subject"
              }`
            )}
          </Button>
          {isDisabledUser() && !process.env.DISABLED_DELETE_SUBJECT && (
            <Button
              key="delete"
              type="primary"
              danger
              ghost
              style={{ height: 40 }}
              onClick={openSubjectDeleteModal}
            >
              {t("profile.form.delete_subject")}
            </Button>
          )}
        </div>
      )}
      {isAdmin && (
        <DeleteModalComponent
          isModalOpen={isOpenDeleteModal}
          handleCancel={closeSubjectDeleteModal}
          handleOk={deleteSubject}
          item={subject}
        />
      )}
    </Body>
  );
};

export default Profile;
