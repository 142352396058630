import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import TagTable from "./tag-table";
import TagCard from "./tag-card/tag-card";
import ApiTagManagement from "../../../api/tag-managements";
import useNotification from "../../../components/notification/notification";
import "./styles.css";
import { Body } from "../../../components/main-body/body";
import { useForm } from "antd/es/form/Form";
import { Modal } from "antd";
import debounce from "../../../common/utils/debounce";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";

const DeleteModalComponent = ({
  item,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={item?.name}
      open={isModalOpen}
      onOk={() => {
        handleOk(item.id, item.name);
      }}
      okText="Delete"
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to delete this tag?</p>
    </Modal>
  );
};

const TagManagement = () => {
  const [tags, setTags] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [editMode, setEditMode] = useState();
  const [selectedTag, setSelectedTag] = useState({});
  const [form] = useForm();
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({});

  // const openTagDrawer = (edit) => {
  //   if (selectedTag?.name) {
  //     setSelectedTag({});
  //   }
  //   if (edit) {
  //     setEditMode(true);
  //   }
  //   setOpen(true);
  // };

  const closeTagDrawer = () => {
    setSelectedTag({});
    setEditMode(false);
    setOpen(false);
  };

  const getTags = (deselect, query = "", currentFilters = filters) => {
    ApiTagManagement.getTags({query, ...currentFilters}).then(({ data }) => {
      const { tags } = data || {};

      setTags(tags);
    });
  };

  useEffect(() => {
    debouncedGetTags(searchValue, filters)
  }, [searchValue, filters]); 


  const debouncedGetTags = useCallback(
    debounce((query, currentFilters) => getTags(false, query, currentFilters), 400),
    [filters]
  );

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
  };

  const headerProps = useMemo(
    () => ({
      title: "Tag management",
      // create: {
      //   label: t("administration.tag.create"),
      //   onClick: () => openTagDrawer(true),
      // },
      search: {
        value: searchValue,
        onHandleChange: handleUpdateSearchValue,
      },
    }),
    [isOpen, selectedTag]
  );

  useEffect(() => {
    getTags();
  }, []);

const updateFilterValue = (newFilters) => {
  setFilters(newFilters);
};

  const createTag = (tag) => {
    closeTagDrawer();
    ApiTagManagement.addTag(tag)
      .then(() => {
        getTags();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.tag.notification.created", {
                tag: tag.name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.tag.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const updateTag = (id, tag) => {
    closeTagDrawer();
    ApiTagManagement.updateTag(id, tag)
      .then(() => {
        getTags();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.tag.notification.updated", {
                tag: tag.name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.tag.notification.${
                  e?.response?.data?.error?.code || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const onHandleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const onCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const deleteTag = (id, name) => {
    setIsDeleteModalOpen(false);
    closeTagDrawer();
    ApiTagManagement.deleteTag(id)
      .then(() => {
        getTags();
      })
      .then(() => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.tag.notification.deleted", {
                tag: name,
              })}
            </p>
          ),
        });
      })
      .catch((e) => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t(
                `administration.tag.notification.${
                  e?.response?.data?.error || "error"
                }`
              )}
            </p>
          ),
        });
      });
  };

  const handleSelectTag = (tag) => {
    setSelectedTag(tag);
    setOpen(true);
  };

  const toEditMode = () => {
    setEditMode(true);
  };

  useEffect(() => {
    form.resetFields();
  }, [selectedTag, editMode]);

  const sources = [{ id: 'article', name: 'Article'}, { id: 'fitfact', name: 'FitFact'}]

  return (
    <Body
      header={headerProps}
      filters={
        <DropdownFilters
          filtersBy={[
            { name: "Source", id: "sources", children: sources }
          ]}
          updateFilterValue={updateFilterValue}
        />
      }
    >
      <TagTable
        data={tags}
        onHandleRowClick={handleSelectTag}
      />
      <TagCard
        tag={selectedTag}
        isOpen={isOpen}
        close={closeTagDrawer}
        editMode={editMode}
        toEditMode={toEditMode}
        createTag={createTag}
        updateTag={updateTag}
        deleteTag={onHandleDelete}
        form={form}
      />
      {
        <DeleteModalComponent
          item={selectedTag}
          isModalOpen={isDeleteModalOpen}
          handleCancel={onCloseDeleteModal}
          handleOk={deleteTag}
        />
      }
    </Body>
  );
};

export default TagManagement;
