import moment from "moment";
import { useTranslation } from "react-i18next";
import { TableCell } from "../../../components/table/table-cell/table-cell";
import InfinityTable from "../../../components/infinity-table/infinity-table";
import encodeSvgWithColor from "../../../common/utils/encodeSvgWithColor";

const TaskTable = ({ data, onHandleRowClick, onChange }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("task_management.table.task"),
      dataIndex: "title",
      render: (_, task) => {
        const { avatar, avatarColor, backgroundColor, title } = task;
        return (
          <TableCell
            img={avatar ? encodeSvgWithColor(avatar, avatarColor) : " "}
            imgStyle={{
              backgroundColor: backgroundColor,
              backgroundSize: "50%",
            }}
            title={title}
          />
        );
      },
      sorter: true,
      key: "title",
      width: "20%",
    },
    {
      title: t("task_management.table.category"),
      dataIndex: "category",
      render: (_, task) => {
        return <TableCell text={task.Category?.name || "N/A"} />;
      },
      key: "category",
      width: "20%",
      sorter: true,
    },
    {
      title: t("task_management.table.plans"),
      dataIndex: "plans",
      render: (_, task) => {
        const planNames = (task.Plans || [])
          .map((p) => p.title)
          .sort((a, b) => a.localeCompare(b))
          .join(", ");

        return <TableCell text={planNames || "N/A"} />;
      },
      key: "plans",
      width: "20%",
      sorter: true,
    },
    {
      title: t("task_management.table.last_modified"),
      dataIndex: "updatedAt",
      render: (_, task) => {
        return (
          <TableCell
            text={
              `${moment(task.updatedAt)
                .utc()
                .format("DD MMM YYYY, HH:mm")}, UTC` || "N/A"
            }
          />
        );
      },
      sorter: true,
      key: "updatedAt",
      width: "20%",
    },
  ];
  return (
    <InfinityTable
      data={data}
      fetchData={console.log}
      hasMore={false}
      tableColumns={columns}
      isLoading={false}
      onHandleRowClick={onHandleRowClick}
      onChange={onChange}
    />
  );
};

export default TaskTable;
