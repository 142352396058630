import { useTranslation } from "react-i18next";
import UserActivityStatus from "../../../components/user-activity-status/user-activity-status";
import getDateByDiff from "../../../common/utils/calendar/get-date-by-diff";
import InfinityTable from "../../../components/infinity-table/infinity-table";
import {TableCell} from "../../../components/table/table-cell/table-cell";
import classNames from "classnames";
import { useState, useCallback } from "react";

const SubjectsList = ({
  subjects,
  fetchUsers,
  hasMore,
  isLoading,
  onHandleRowClick,
}) => {
  const { t } = useTranslation();
  const [sorting, setSorting] = useState({
    sortField: "last_active",
    sortOrder: "DESC"
  });

  const getSortedSubjects = useCallback(() => {
    if (!subjects || !sorting.sortField) return subjects;

    const getComparableValue = (val) => {
      if (val === null || val === undefined) return '';
      if (typeof val === 'string') {
        const parsed = Date.parse(val);
        return isNaN(parsed) ? val.toLowerCase() : parsed;
      }
      return val;
    };

    const sorted = [...subjects].sort((a, b) => {
      const field = sorting.sortField === "last_active" ? "lastActivity" : sorting.sortField;
      const aVal = getComparableValue(a[field]);
      const bVal = getComparableValue(b[field]);

      if (aVal < bVal) return sorting.sortOrder === "ASC" ? -1 : 1;
      if (aVal > bVal) return sorting.sortOrder === "ASC" ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [subjects, sorting]);

  const updateSorting = useCallback((sortField, sortOrder) => {
    setSorting({ sortField, sortOrder });
  }, []);

  const onChange = (pagination, filters, sorter) => {
    console.log('ONCHANGE', sorter)
    const sortField = sorter.field;
    let sortOrder;

    if (!sorter.order) {
      sortOrder = "ASC";
    } else {
      sortOrder = sorter.order === "ascend" ? "ASC" : "DESC";
    }
    updateSorting(sortField, sortOrder);
  };

  const tableColumns = [
    {
      title: t("subject_management.table.subjectId"),
      dataIndex: "id",
      render: (_, user) => <TableCell title={user.displayId || "N/A"}/>,
      key: "id",
      width: "25%",
      sorter: true,
    },
    {
      title: t("subject_management.table.cohort"),
      dataIndex: "cohort",
      render: (_, user) => <TableCell text={user.cohort || "N/A"}/>,
      key: "cohort",
      width: "25%",
      sorter: true,
    },
    {
      title: t("subject_management.table.status"),
      dataIndex: "status",
      key: "status",
      render: (_, user) => <div className={classNames("lf-table-cell")}><UserActivityStatus userStatus={user.status}/></div>,
      width: "25%",
      sorter: true,
    },
    {
      title: t("subject_management.table.last_active"),
      dataIndex: "last_active",
      key: "last_active",
      render: (_, user) => <TableCell text={getDateByDiff(user.lastActivity)}/>,
      sorter: true,
    },
  ];

  return (
    <InfinityTable
      data={getSortedSubjects()}
      fetchData={fetchUsers}
      hasMore={hasMore}
      tableColumns={tableColumns}
      isLoading={isLoading}
      onHandleRowClick={onHandleRowClick}
      onChange={onChange}
    />
  );
};

export default SubjectsList;
